import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MapIcon from '../../../assets/img/about/ico-map.png';
import TelIcon from '../../../assets/img/about/ico-tel.png';
import FaxIcon from '../../../assets/img/about/ico-fax.png';

function MapView({ mapInfo }) {
  const mapElement = useRef(null);

  useEffect(() => {
    const { naver } = window;
    if (!mapElement.current || !naver) return;

    // 지도에 표시할 위치의 위도와 경도 좌표를 파라미터로 넣어줍니다.
    const location = new naver.maps.LatLng(
      mapInfo['location']?.lat,
      mapInfo['location']?.lng,
    );
    const mapOptions = {
      center: location,
      zoom: 17,
      zoomControl: true,
      zoomControlOptions: {
        position: naver.maps.Position.TOP_RIGHT,
      },
    };
    const map = new naver.maps.Map(mapElement.current, mapOptions);
    const marker = new naver.maps.Marker({
      position: location,
      map,
    });
  }, [mapInfo]);

  return (
    <StyledContentContainer id="content">
      {mapInfo && mapInfo['address'] && (
        <article className="c-contents">
          <div className="inner-container__sub">
            <div ref={mapElement} className="c-location__map" />
            <ul className="c-location__list">
              <li className="add">
                <strong>{mapInfo['address']?.title ?? '지점'}</strong>
                <span>{mapInfo['address']?.value ?? '주소'}</span>
              </li>
              <li className="tel">
                <strong>{mapInfo['tel']?.title ?? '전화'}</strong>
                <span>{mapInfo['tel']?.value ?? '02)999-9999'}</span>
              </li>
              <li className="fax">
                <strong>{mapInfo['fax']?.title ?? '팩스'}</strong>
                <span>{mapInfo['fax']?.value ?? '02)222-2222'}</span>
              </li>
            </ul>
          </div>
        </article>
      )}
    </StyledContentContainer>
  );
}

const StyledContentContainer = styled.section`
  .c-location__map {
    margin-top: 6rem;
    min-height: 66rem;
    border: 1px solid var(--color-gray-c8c8c8);
  }
  .c-location__list {
    margin-top: 3.2rem;
  }
  .c-location__list li {
    position: relative;
  }
  .c-location__list li.tel strong,
  .c-location__list li.fax strong {
    font-family: var(--font-Montserrat);
  }
  .c-location__list li strong {
    margin-right: 2.4rem;
  }
  @media all and (min-width: 1401px) {
    .c-location__list {
      display: flex;
    }
    .c-location__list li + li {
      margin-left: 10rem;
    }
  }
  @media all and (min-width: 769px) and (max-width: 1400px) {
    .c-location__list li {
      display: inline-block;
      margin-bottom: 1.6rem;
    }
    .c-location__list li.add {
      width: 100%;
    }
    .c-location__list li.tel {
      margin-right: 14rem;
    }
    .c-location__list li strong {
      display: inline-block;
      width: 10rem;
    }
  }
  @media all and (min-width: 769px) {
    .c-location__list li {
      padding-left: 4rem;
      font-size: 1.7rem;
    }
    .c-location__list li.tel strong,
    .c-location__list li.fax strong {
      font-family: var(--font-Montserrat);
    }
    .c-location__list li strong::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: inline-block;
      width: 32px;
      height: 32px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 32px;
    }
    .c-location__list li.add strong::before {
      background-image: url(${MapIcon});
    }
    .c-location__list li.tel strong::before {
      background-image: url(${TelIcon});
    }
    .c-location__list li.fax strong::before {
      background-image: url(${FaxIcon});
    }
  }
  @media all and (max-width: 768px) {
    .c-location__list li {
      display: block;
      padding-bottom: 1.6rem;
      font-size: 1.7rem;
    }
    .c-location__list li + li {
      padding-top: 1.6rem;
      border-top: 1px dashed var(--color-gray-d9d9d9);
    }
    .c-location__list li strong {
      display: block;
    }
  }
`;

MapView.propTypes = {
  mapInfo: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
};

MapView.defaultProps = {
  mapInfo: {},
};

export default MapView;
