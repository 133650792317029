import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import MainLayout from './components/templates/MainLayout';
import SiteLayout from './components/templates/SiteLayout';
import routes from './config/router/route';
import RouteChangeTracker from './config/router/RouteChangeTracker';
import { setMenu } from './store/menu/reducer';
import { withInitializeProvider } from './hoc/withInitializeProvider';
import useStoreMenu from './hooks/useStoreMenu';

import theme from './config/utils/theme';
import Main from './components/pages/Main';

import {
  SESSION_STORAGE,
  PERSISTENT_INFO,
  SEARCH_KEY,
} from './config/constants';

function App() {
  const dispatch = useDispatch();
  const { menues: menuDatas } = useStoreMenu();

  /**
   * 애널리틱스 적용
   */
  RouteChangeTracker();

  const handleSetMenu = menu => {
    const newMenu = {
      ...menu,
      type: 1,
    };

    // 메뉴 유지를 위한 설정
    SESSION_STORAGE.setItem(
      PERSISTENT_INFO,
      JSON.stringify({
        ...newMenu,
      }),
    );

    dispatch(setMenu(newMenu));
  };

  /**
   * 검색 정보 초기화
   */
  const handleSearchClear = () => {
    SESSION_STORAGE.removeItem(SEARCH_KEY);
  };

  const props = {
    menues: menuDatas,
    onSetMenu: handleSetMenu,
    onSearchClear: handleSearchClear,
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        {/* skipNavigation */}
        <div id="skipNavi">
          <a href="#content">본문 바로가기</a>
          <a href="#gnb">대메뉴 바로가기</a>
        </div>
        <Routes>
          {routes.map(route => {
            if (route.isNoneLayout) {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={<route.element />}
                />
              );
            }
            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <SiteLayout {...props}>
                    <route.element />
                  </SiteLayout>
                }
              >
                {route?.children?.map(subroute => (
                  <Route
                    key={subroute.path}
                    path={subroute.path}
                    element={<subroute.element />}
                  />
                ))}
              </Route>
            );
          })}
          <Route
            path="/main"
            element={
              <MainLayout {...props}>
                <Main {...props} />
              </MainLayout>
            }
          />
          <Route path="*" element={<Navigate to="/main" />} />
        </Routes>
      </div>
    </ThemeProvider>
  );
}

export default withInitializeProvider(App);
