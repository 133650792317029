import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MainView from './MainView';

import { useQueries } from '../../../hooks/useQuery';

/**
 * 사이트 관리
 * @returns
 */
function Main(props) {
  const { menues, onSetMenu } = props;
  const [popups, setPopups] = useState([]);

  const [{ data: data1 }, { data: data2 }] = useQueries([
    [
      '/api/article/findAll',
      {
        pageIndex: 1,
        pageSize: 10,
        recordCountPerPage: 10,
        searchCnd: '',
        searchWrd: '',
        bbsId: 'BBSMSTR_000000000013', // 홍보자료
        siteId: 'SITE_000000000000003',
        mainAt: 'Y',
      },
    ],
    [
      '/api/article/findAll',
      {
        pageIndex: 1,
        pageSize: 10,
        recordCountPerPage: 10,
        searchCnd: '',
        searchWrd: '',
        bbsId: 'BBSMSTR_000000000011', // 보도자료
        siteId: 'SITE_000000000000003',
        mainAt: 'Y',
      },
    ],
  ]);

  const handleGetMenuObj = (upperMenuNo, menuNo) => {
    return menues
      .filter(m => m.menuNo === upperMenuNo)[0]
      ?.children?.filter(m => m.menuNo === menuNo && m)[0];
  };

  const newProps = {
    ...props,
    promotion: data1,
    sidework: data2,
    onSetMenu,
    onGetMenuObj: handleGetMenuObj,
  };

  return <MainView {...newProps} />;
}

Main.propTypes = {
  menues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array])),
  onSetMenu: PropTypes.func,
};

Main.defaultProps = {
  menues: [],
  onSetMenu: () => {},
};

export default Main;
