import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { useQuery } from '../../../hooks/useQuery';
import { SITE_ID } from '../../../config/constants';
import { setMenu } from '../../../store/menu/reducer';
import { getTreeMenu } from '../../../config/utils/util';

import PolicyView from './PolicyView';

/**
 * 회사소개
 * @returns
 */
function Policy() {
  const dispatch = useDispatch();
  const menues = useSelector(state => state.menu.menues, shallowEqual);
  const { id: useStplatId } = useParams();
  // const location = useLocation();
  // const navigate = useNavigate();
  const [detail, setDetail] = useState();

  const {
    data,
    refetch: refetchList,
    // ...restQuery
  } = useQuery(
    '/api/terms/find',
    { useStplatId, siteId: SITE_ID },
    {
      cacheTime: 0,
      enabled: !detail,
      select: res => {
        return res.result;
      },
    },
  );

  useEffect(() => {
    if (menues && menues.length > 0) {
      const menu = getTreeMenu(menues, useStplatId);
      if (menu) {
        dispatch(setMenu(menu));
      }
    }
  }, [menues, useStplatId, dispatch]);

  const props = {
    data,
  };

  return <PolicyView {...props} />;
}

export default Policy;
