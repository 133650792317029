import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function CategoryView({
  category,
  categoryOption,
  onCategoryTrigger,
  onSetRef,
}) {
  const ref = useRef();
  const { initialValues, categories } = categoryOption;

  useEffect(() => {
    onSetRef(ref);
  }, []);

  return (
    <div className="c-bbs__tab">
      <ul className="tab__list">
        {categories &&
          categories?.map(c => {
            return (
              <li
                className={`tab__list--item ${
                  c.key === (category || (initialValues.bbsCode ?? '')) &&
                  'active'
                }`}
              >
                <Link
                  onClick={event => {
                    onCategoryTrigger && onCategoryTrigger(event, c.key);
                  }}
                >
                  {c.name}
                </Link>
              </li>
            );
          })}
      </ul>
    </div>
  );
}

CategoryView.propTypes = {
  category: PropTypes.string.isRequired,
  categoryOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ).isRequired,
  onCategoryTrigger: PropTypes.func.isRequired,
  onSetRef: PropTypes.func.isRequired,
};

export default CategoryView;
