import { combineReducers } from '@reduxjs/toolkit';
import menu from './menu/reducer';
/**
 * Reducer Combine 용
 * store를 만들고 등록하면 된다.
 * 예)
 * const reducer = combineReducers({
 *  common,
 *  site,
 *  menu,
 *  auth,
 * });
 */
const reducer = combineReducers({
  menu,
});

export default reducer;
