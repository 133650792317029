// 라이브러리
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// 공통 컴포넌트
import { useQuery } from '../../../../hooks/useQuery';
import { SITE_ID } from '../../../../config/constants';

import { getTreeMenu } from '../../../../config/utils/util';

// 페이지
import DetailView from './DetailView';

/**
 * 회사소개
 * @returns
 */
function Detail() {
  const { id: bbsId, nttrId } = useParams();

  /**
   * API - 상세정보 조회
   */
  const { data: detail } = useQuery(
    '/api/article/find',
    { siteId: SITE_ID, bbsId, nttId: nttrId },
    {
      cacheTime: 0,
      enabled: !!bbsId && !!nttrId,
    },
  );

  useEffect(() => {
    const element = document.getElementById('content');
    element?.scrollIntoView();
  }, []);

  /**
   * props 세팅
   */
  const props = {
    detail,
    nttrId,
  };

  return <DetailView {...props} />;
}

export default Detail;
