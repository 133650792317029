/* eslint-disable react/prop-types */
/* eslint-disable no-template-curly-in-string */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import SwiperCore, { Navigation, Pagination, Scrollbar } from 'swiper';

const CustomSwiperView = React.forwardRef(
  (
    { sliders, sliderOptions, breakpoints, setPrev, setTotal, onSetMenu },
    ref,
  ) => {
    setTotal(sliders.length - 1 ?? 1);

    SwiperCore.use([Navigation, Pagination, Scrollbar]);
    return (
      <StyledSwiper
        {...sliderOptions}
        breakpoints={breakpoints}
        navigation
        onBeforeInit={swiper => {
          ref.current = swiper;
        }}
        pagination={{
          type: 'progressbar',
          //   clickable: true,
        }}
        // onPaginationUpdate={swiper => {
        //   // setPrev((swiper?.realIndex ?? 0) + 1);
        //   // console.log('swiper =>', swiper);
        // }}
        onTransitionEnd={swiper => {
          setPrev((swiper?.realIndex ?? 0) + 1);
        }}
      >
        {sliders?.map(slider => {
          return <SwiperSlide>{slider}</SwiperSlide>;
        })}
      </StyledSwiper>
    );
  },
);

const StyledSwiper = styled(Swiper)`
  .swiper-slide {
    padding: 2rem 0 3rem;
  }
  a {
    position: relative;
    display: block;
    transition: all 0.35s ease;
    &:hover {
      transform: translateY(-1rem);
      .thumb {
        img {
          transform: scale(1.2);
        }
      }
    }
    .thumb {
      position: relative;
      margin-top: 3rem;
      height: 0;
      padding-bottom: 75%;
      overflow: hidden;
      > div {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        img {
          transform: scale(1);
          transition: all 0.35s ease;
          height: 100%;
          width: 100%;
          margin: auto;
          display: block;
          object-fit: cover;
        }
      }
    }
    .desc {
      padding: 3rem 0 0;
      /* height: 136px; */
      span {
        position: absolute;
        top: -3rem;
        color: var(--color-gray-a0a0a0);
        font-size: 1.6rem;
        font-weight: 300;
      }
      strong {
        position: relative;
        display: block;
        display: -webkit-box;
        margin-bottom: 1.4rem;
        font-size: 1.9rem;
        font-weight: 500;
        line-height: 1.4;
        word-break: break-all;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        z-index: 1;
      }
    }
  }
  /*  */
  &__list {
    display: flex;
    gap: 6rem;
    li {
      width: calc(100% / 4);
      a {
        position: relative;
        display: block;
        transition: all 0.35s ease;
        &:hover {
          transform: translateY(-1rem);
          .thumb {
            img {
              transform: scale(1.2);
            }
          }
        }
        .thumb {
          margin-top: 3rem;
          overflow: hidden;
          img {
            transform: scale(1);
            transition: all 0.35s ease;
            object-fit: cover;
          }
        }
        .desc {
          padding: 3rem 0;
          height: 136px;
          span {
            position: absolute;
            top: -3rem;
            color: var(--color-gray-a0a0a0);
            font-size: 1.6rem;
            font-weight: 300;
          }
          strong {
            position: relative;
            display: block;
            display: -webkit-box;
            margin-bottom: 1.4rem;
            font-size: 2.3rem;
            line-height: 1.4;
            word-wrap: break-word;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            z-index: 1;
          }
        }
      }
    }
  }
  /*  */

  .swiper-button-next,
  .swiper-button-prev {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    color: var(--color-black);
    /* &.swiper-button-disabled {
      opacity: 0;
    } */
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    right: 4rem;
  }
  .swiper-pagination-progressbar {
    top: auto;
    bottom: 1rem;
    right: 7rem;
    width: auto;
    height: 2px;
    background: var(--color-black-rgba-10);
    .swiper-pagination-progressbar-fill {
      background: var(--color-black-rgba-25);
    }
  }
  @media ${({ theme }) => theme.media.xxlMin} {
    /* .swiper-button-next,
    .swiper-button-prev {
      &.swiper-button-disabled {
        opacity: 0;
      }
    } */
  }
  @media ${({ theme }) => theme.media.smMin} {
    .swiper-pagination-progressbar {
      display: none;
    }
  }
`;

CustomSwiperView.propTypes = {
  sliders: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.array])),
  sliderOptions: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.object])),
  setPrev: PropTypes.func,
  setTotal: PropTypes.func,
  onSetMenu: PropTypes.func,
  selectedMenuObj: PropTypes.objectOf,
};

CustomSwiperView.defaultProps = {
  sliders: [],
  sliderOptions: {},
  setPrev: () => {},
  setTotal: () => {},
  onSetMenu: () => {},
  selectedMenuObj: {},
};
export default CustomSwiperView;
