import React from 'react';
import styled, { css } from 'styled-components';

const Size = {
  xs: css`
    --button-font-size: 1.3rem;
    --button-padding: 0.5rem 1.5rem;
    /* --button-radius: 0.25rem; */
  `,
  sm: css`
    --button-font-size: 1.4rem;
    --button-padding: 0.8rem 2.5rem;
    /* --button-radius: 0.25rem; */
  `,
  md: css`
    --button-font-size: 1.5rem;
    --button-padding: 1.6rem 5rem;
    /* --button-radius: 0.25rem; */
  `,
  lg: css`
    --button-font-size: 1.6rem;
    --button-padding: 2.25rem 8rem;
    /* --button-radius: 0.5rem; */
  `,
  xl: css`
    --button-font-size: 2.1rem;
    --button-padding: 2.5rem 8rem;
    /* --button-radius: 0.5rem; */
  `,
};
const Variant = {
  default: css`
    --button-color: #fff;
    --button-bg-color: #001650;
    --button-hover-bg-color: #00113d;
  `,
  normal: css`
    --button-color: #333;
    --button-border-color: #d9d9d9;
    --button-bg-color: #fff;
    --button-hover-bg-color: #eaeaea;
  `,
  info: css`
    --button-color: #fff;
    --button-bg-color: #111;
    --button-hover-bg-color: #000;
  `,
  primary: css`
    --button-color: #fff;
    --button-bg-color: #0d6efd;
    --button-hover-bg-color: #0757cd;
  `,
  success: css`
    --button-color: #fff;
    --button-bg-color: #28a745;
    --button-hover-bg-color: #218838;
  `,
  error: css`
    --button-color: #fff;
    --button-bg-color: #dc3545;
    --button-hover-bg-color: #c82333;
  `,
  warning: css`
    --button-color: #212529;
    --button-bg-color: #ffc107;
    --button-hover-bg-color: #e0a800;
  `,
  link: css`
    --button-color: #333;
    --button-bg-color: transparent;
    --button-hover-bg-color: transparent;
  `,
};

// eslint-disable-next-line react/prop-types
// function Button({ disabled, size, variant, type, onClick, children, ...rest }) {
//   const sizeStyle = Size[size];
//   const variantStyle = Variant[variant];
//   return (
//     <StyledButton
//       disabled={disabled}
//       sizeStyle={sizeStyle}
//       variantStyle={variantStyle}
//       onClick={onClick}
//       type={type}
//       {...rest}
//     >
//       {children}
//     </StyledButton>
//   );
// }

const ComponentStyle = styled.button`
  ${p => p.sizeStyle}
  ${p => p.variantStyle}
    display: inline-block;
  margin: 0;
  font-size: var(--button-font-size, 1.5rem);
  padding: var(--button-padding, 1rem 5rem);
  border: var(--button-border-width, 1px) solid
    var(--button-border-color, transparent);
  border-radius: var(--button-radius, 0);
  color: var(--button-color, #000);
  background: var(--button-bg-color, #eaeaea);
  transition: opacity 0.8s ease, all 0.3s ease;
  /* cursor: pointer; */

  &:active,
  &:hover,
  &:focus {
    background: var(--button-hover-bg-color, #d9d9d9);
  }
  &:disabled {
    cursor: default;
    opacity: 0.5;
    background: var(--button-bg-color, #d9d9d9);
  }
`;

// eslint-disable-next-line react/prop-types
const Button = props => {
  // eslint-disable-next-line react/prop-types, no-unused-vars
  const { disabled, size, variant, type, onClick, children, ...rest } = props;
  // eslint-disable-next-line no-nested-ternary, react/destructuring-assignment, react/prop-types
  const tag = props.href ? 'a' : 'button';
  const sizeStyle = Size[size];
  const variantStyle = Variant[variant];

  return (
    <ComponentStyle
      as={tag}
      sizeStyle={sizeStyle}
      variantStyle={variantStyle}
      onClick={onClick}
      disabled={disabled}
      type={type}
      {...props}
    />
  );
};

export default Button;
