import React, { useMemo, useState } from 'react';
import { useFormik } from 'formik';
import { useQuery, useMutation } from '../../../hooks/useQuery';

import InquiryView from './InquiryView';
import { SITE_ID } from '../../../config/constants';
/**
 * 플레이그라운드
 * @returns
 */
function Inquiry() {
  /*
    https://api.reacting.kr/api/ctt/insertCtt

    {
        "contactor": "테스트",
        "email": "test@test.com",
        "company": "테스트 컴페니",
        "phoneNumber": "000-9999-0000",
        "words": "문의 내용 입니다.\n문의 문의",
        "regId": "테스트",
        "siteId": "SITE_000000000000091"
    }
  */

  const [valueEmailOptions, setValueEmailOptions] = useState('');

  /**
   * API - 정보저장
   */
  const saveMutation = useMutation('/api/ctt/insertCtt');

  const formInitialValues = useMemo(
    () => ({
      cttSeCd: '',
      contactor: '',
      email1: '',
      email2: '',
      phoneNumber1: '',
      phoneNumber2: '',
      phoneNumber3: '',
      cttSj: '',
      words: '',
      checkPrivacy: false,
    }),
    [],
  );

  const formik = useFormik({
    initialValues: formInitialValues,
    onSubmit: values => {
      // check privacy
      if (!values.checkPrivacy) {
        alert('개인정보처리방침 동의 후 등록 가능합니다.');
        return;
      }
      // validate values
      if (!values.cttSeCd) {
        alert('구분은 필수 입력입니다.');
        return;
      }
      if (!values.contactor) {
        alert('작성자는 필수 입력입니다.');
        return;
      }
      const phoneRegex = /^[0-9]*$/;
      const phoneNumber = `${values.phoneNumber1}${values.phoneNumber2}${values.phoneNumber3}`;
      if (!phoneRegex.test(phoneNumber)) {
        alert('연락처에는 숫자만 입력해주세요.');
        return;
      }
      if (!values.email1 || !values.email2) {
        alert('이메일은 필수 입력입니다.');
        return;
      }
      const emailRegex = /[a-z]+[.][a-z]{2,3}/;
      if (!emailRegex.test(values.email2)) {
        alert('이메일 형식이 잘못되었습니다.');
        return;
      }
      if (!values.cttSj) {
        alert('제목은 필수 입력입니다.');
        return;
      }
      if (!values.words) {
        alert('문의내용은 필수 입력입니다.');
        return;
      }

      if (window.confirm('상담내용을 등록하시겠습니까?')) {
        saveMutation.mutate(
          {
            ...values,
            email: `${values.email1}@${values.email2}`,
            phoneNumber: `${values.phoneNumber1 || ''}${
              values.phoneNumber2 || ''
            }${values.phoneNumber3 || ''}`,
            siteId: SITE_ID,
            company: 'baneung',
            regId: 'system',
          },
          {
            onSuccess: res => {
              if (res?.status === 200) {
                // Message
                handleReset();
                alert('저장 되었습니다.');
              } else {
                const { status, statusText } = res;
                alert(`[${status} - ${statusText}]`);
              }
            },
            onError: async error => {
              console.error(
                '🚀 ~ updateMutation : error2',
                JSON.stringify(error),
              );
            },
            onSettled: async () => {
              // console.log('🚀 ~ updateMutation onSettled!!!');
              // Form Reset
              handleReset();
            },
          },
        );
      }
    },
  });

  const handleReset = () => {
    formik.resetForm({ values: formInitialValues });
    setValueEmailOptions('');
  };

  const handleChangeEmailOptions = e => {
    const { value } = e.target;
    formik.setFieldValue('email2', value);
    setValueEmailOptions(value);
  };

  // input 숫자만 받기
  const handleNumberKey = input => {
    const regex = /[^0-9]/g; // 숫자만 입력할 수 있도록 하는 정규식
    const inputId = input?.target?.id; // input ID값
    const inputValue = document.getElementById(inputId).value;
    formik.setFieldValue(inputId, inputValue.replace(regex, ''));
  };

  const props = {
    formik,
    valueEmailOptions,
    onChangeEmailOptions: handleChangeEmailOptions,
    onChangeNumberKey: handleNumberKey,
  };

  return <InquiryView {...props} />;
}

export default Inquiry;
