import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import SiteHeader from '../SiteHeader';
import SiteFooter from '../SiteFooter';

function MainLayoutView(props) {
  const { children } = props;

  const newProp = {
    ...props,
    pageType: 'main',
  };

  return (
    <SiteLayout className="site-layout">
      <SiteHeader {...newProp} />
      <SiteContent {...newProp}>{children}</SiteContent>
      <SiteFooter {...newProp} />
    </SiteLayout>
  );
}

const SiteLayout = styled.div`
  min-height: 100vh;
`;
const SiteContent = styled.main`
  /* padding-top: 7.2rem; */
`;

export default MainLayoutView;

MainLayoutView.propTypes = {
  children: PropTypes.element.isRequired,
};
