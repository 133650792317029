/**
 * 바능 CMS Demo 페이지
 */
import Inquiry from '../../components/pages/Inquiry';
import Policy from '../../components/pages/Policy';
import Contents from '../../components/pages/Contents';
import Map from '../../components/pages/Map';
import Board from '../../components/pages/Board';
import Detail from '../../components/pages/Board/Detail';
import RealEstateDetail from '../../components/pages/Board/RealEstateDetail';

const Routes = [
  /**
   * 약관 라우터
   * type: 약관/개인정보/이메일수집 구분자 (policy/private/email)
   */
  {
    element: Policy,
    path: `/policy/:id`,
  },
  // 컨텐츠
  {
    element: Contents,
    path: `/contents/:id`,
  },
  // 지도
  {
    element: Map,
    path: `/map/:type`,
  },
  // 게시판
  {
    element: Board,
    path: '/board/:mid',
  },
  // 게시판 상세
  {
    element: Detail,
    path: '/board/:id/detail/:nttrId',
  },
  // 물건공고 게시판 상세(임시)
  {
    element: RealEstateDetail,
    path: '/board/:id/realEstateDetail/:nttrId',
  },
  // k > 고객지원 > 고객상담
  {
    element: Inquiry,
    path: '/inquiry',
  },
];

export default Routes;
