import React from 'react';
import PropTypes from 'prop-types';

import BoardView from './BoardView';

/**
 * 게시판 컴포넌트
 * @param {object} paging 페이징 정보
 * @param {Array} notiList 공지사항 정보(상단 공지 적용 게시판 용)
 * @param {Array} dataList 데이터(목록 정보)
 * @returns 게시판 컴포넌트
 */
function Board(props) {
  const { paging, onSearch, onCategory, onPaging } = props;

  /**
   * props 세팅
   */
  const newProps = {
    ...props,
    paging: { ...paging, onPaging },
    onCategory,
    onSearch,
  };

  return <BoardView {...newProps} />;
}

Board.propTypes = {
  search: PropTypes.objectOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  ),
  paging: PropTypes.objectOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.func]),
  ),
  onSearch: PropTypes.func,
  onCategory: PropTypes.func,
  onPaging: PropTypes.func,
};

Board.defaultProps = {
  search: {},
  paging: {},
  onSearch: () => {},
  onCategory: () => {},
  onPaging: () => {},
};

export default Board;
