import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

function SiteContentView(props) {
  const {
    children,
    selMenu,
    onSetMenu,
    onSetClose,
    isClose,
    onSearchClear,
    on3DepthMenuToggle,
  } = props;

  const handleSetClassName = (m, index) => {
    if (!selMenu?.child?.child?.menuNo) {
      if (index === 0) {
        return 'active';
      }
    }

    if (m.menuNo === selMenu?.child?.child?.menuNo) {
      return 'active';
    }
  };

  return (
    <ContentWrap id="content" className="inner-container">
      <div className="sub-tab">
        <ul className="sub-tab__list">
          {selMenu &&
            selMenu.children?.length > 1 &&
            selMenu?.children?.map(m => {
              return (
                m.gnbAt === 'Y' && (
                  <li
                    className={`${
                      m.menuNo === selMenu?.child?.menuNo ? 'active' : ''
                    }`}
                    key={m.menuNo}
                  >
                    <Link
                      to={`${m.menuUrl}?type=contents`}
                      onClick={() => {
                        onSetMenu && onSetMenu(m);
                        onSearchClear && onSearchClear();
                      }}
                    >
                      <span>{m.menuNm}</span>
                    </Link>
                  </li>
                )
              );
            })}
        </ul>
      </div>
      {/* 2depth menu */}
      {selMenu && (
        <div className="c-contents">
          {![9110009, 9110013, 9110018].find(
            m => m === selMenu?.child?.menuNo,
          ) && <h3>{selMenu?.child?.menuNm}</h3>}
          {/* 3depth menu */}
          {selMenu?.child?.children?.length > 0 && (
            <div className="inner-tab">
              {/* 열렸을때 close 클래스 붙음 */}
              <button
                type="button"
                className={`inner-tab__btn${isClose ? ' close' : ''}`}
                onClick={on3DepthMenuToggle}
              >
                <span>
                  {selMenu?.child?.child?.menuNm ?? '을지로 본점'}
                  <span className="sr-only">닫기</span>
                </span>
              </button>
              <ul className={`inner-tab__list${isClose ? ' open' : ''}`}>
                {selMenu?.child?.children?.map((m, index) => (
                  <li className={handleSetClassName(m, index)}>
                    <Link
                      to={`${m.menuUrl}?type=contents`}
                      onClick={() => {
                        onSetMenu && onSetMenu(m);
                        onSetClose && onSetClose(false);
                        onSearchClear && onSearchClear();
                      }}
                    >
                      {m.menuNm}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {/* 3depth menu */}
          <SiteContent>{children}</SiteContent>
        </div>
      )}
      {/* 2depth menu */}
    </ContentWrap>
  );
}

const ContentWrap = styled.div`
  .inner-container__sub {
    max-width: var(--cantainer-width);
    margin: 0 auto;
    padding: 0 2.4rem;
  }
  h3 {
    position: relative;
    margin: 0 auto 8rem;
    text-transform: uppercase;
    text-align: center;
  }
  h4 {
    font-size: 2.1rem;
    font-weight: 600;
    margin-bottom: 4rem;
  }
  .info-txt {
    font-size: 2.4rem;
    font-weight: 500;
  }
  .info-box {
    font-size: 2rem;
    background: var(--color-gray-F6F8FC);
  }
  /* 서브 tab menu */
  .sub-tab {
    border-bottom: 1px solid #eee;
    &__list {
      display: flex;
      justify-content: center;
      li {
        &.active {
          a {
            color: var(--color-black);
            font-weight: 700;
            span {
              &::after {
                width: 100%;
              }
            }
          }
        }
        a {
          display: block;
          /* font-size: 2.4rem; */
          padding: 0 2rem;
          &:hover {
            color: var(--color-black);
            font-weight: 700;
            span {
              &::after {
                width: 100%;
              }
            }
          }
          span {
            position: relative;
            display: inline-block;
            padding: 0 2.4rem;
            &::after {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              display: block;
              width: 0;
              height: 2px;
              background: var(--color-main);
              transition: all 0.3s ease 0s;
            }
          }
        }
      }
    }
  }
  /* inner-tab */
  .inner-tab {
    position: relative;
    text-align: center;
    margin: 0 2.4rem;
    &__list {
      justify-content: center;
      border: 1px solid #707070;
      li {
        &.active {
          a {
            color: var(--color-white);
            font-weight: 700;
            background: var(--color-main);
            &:hover {
              color: var(--color-white);
            }
            span {
              &::after {
                width: 100%;
              }
            }
          }
        }
        a {
          display: block;
          font-size: 1.6rem;
          background: var(--color-white);
          transition: all 0.3s ease 0s;
          &:hover {
            color: var(--color-black);
            font-weight: 700;
            span {
              &::after {
                width: 100%;
              }
            }
          }
          span {
            position: relative;
            display: inline-block;
            padding: 0 2.4rem;
            line-height: 7rem;
            &::after {
              content: '';
              position: absolute;
              left: 0;
              bottom: 0;
              display: block;
              width: 0;
              height: 3px;
              background: var(--color-main);
              transition: all 0.3s ease 0s;
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.lgMin} {
    h3 {
      margin-bottom: 7rem;
    }
    .info-box {
      padding: 4.5rem 10rem;
    }
    .c-contents {
      margin: 10rem auto 16rem;
    }
    /* inner-tab */
    .inner-tab {
      &__list {
        display: inline-flex;
        li {
          a {
            padding: 1.3rem 4rem;
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.mdMinlg} {
    .c-contents {
      margin: 10rem auto 14rem;
    }
  }
  @media ${({ theme }) => theme.media.smMinlg} {
    h3 {
      margin-bottom: 4rem;
    }
    .info-box {
      padding: 2.5rem 4rem;
    }
    /* inner-tab */
    .inner-tab {
      &__list {
        display: flex;
        li {
          flex: 1;
          a {
            padding: 1.3rem 0;
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.md} {
    .c-contents {
      margin: 6rem auto 7rem;
    }
  }
  @media ${({ theme }) => theme.media.smMin} {
    /* inner-tab */
    .inner-tab {
      &__btn {
        display: none;
      }
    }
    h3 {
      font-size: 4rem;
    }
    .sub-tab {
      &__list {
        li {
          a {
            font-size: 1.7rem;
            span {
              line-height: 6.4rem;
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.sm} {
    h3 {
      font-size: 3.5rem;
      margin-bottom: 3rem;
    }
    .info-box {
      padding: 2rem 2.5rem;
    }
    .sub-tab {
      &__list {
        li {
          a {
            font-size: 1.8rem;
            span {
              line-height: 6.8rem;
              padding-right: 0;
              padding-left: 0;
            }
          }
        }
      }
    }
    /* inner-tab */
    .inner-tab {
      z-index: 101;
      &__btn {
        position: relative;
        display: block;
        width: 100%;
        padding: 1.6rem;
        font-size: 1.7rem;
        border: 1px solid #eee;
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 2.6rem;
          display: inline-block;
          top: 8px;
          width: 8px;
          height: 8px;
          margin-left: 14px;
          border-left: 2px solid var(--color-black);
          border-bottom: 2px solid var(--color-black);
          transform: rotate(-45deg) translateY(calc(-50% + 2rem));
          vertical-align: top;
          transition: all 0.3s ease 0s;
        }
        &.close {
          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 1.6rem;
            display: inline-block;
            width: 8px;
            height: 8px;
            margin-left: 14px;
            border-left: 2px solid var(--color-black);
            border-bottom: 2px solid var(--color-black);
            vertical-align: top;
            transition: all 0.3s ease 0s;
            transform: rotate(135deg) translateY(calc(-50% + 1rem));
          }
        }
        & + .open {
          display: block;
        }
      }
      &__list {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: 0;
        left: 0;
        display: none;
        transition: all 0.3s ease 0s;
        li {
          a {
            padding: 1rem 0;
          }
        }
      }
    }
  }
`;

const SiteContent = styled.main`
  /* padding-top: 7.2rem; */
`;

SiteContentView.propTypes = {
  children: PropTypes.element.isRequired,
  selMenu: PropTypes.objectOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  ),
  isClose: PropTypes.bool,
  onSetMenu: PropTypes.func,
  onSetClose: PropTypes.func,
  onSearchClear: PropTypes.func,
  on3DepthMenuToggle: PropTypes.func,
};

SiteContentView.defaultProps = {
  selMenu: {},
  isClose: false,
  onSetMenu: () => {},
  onSetClose: () => {},
  onSearchClear: () => {},
  on3DepthMenuToggle: () => {},
};

export default SiteContentView;
