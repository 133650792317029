import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import ContentsView from './ContentsView';
import { useQuery } from '../../../hooks/useQuery';
import { setMenu } from '../../../store/menu/reducer';
import { getTreeMenu } from '../../../config/utils/util';

/**
 * 회사소개
 * @returns
 */
function Contents() {
  const dispatch = useDispatch();
  const menues = useSelector(state => state.menu.menues, shallowEqual);
  const { id: cntntsId } = useParams();
  const [detail, setDetail] = useState();

  const {
    data,
    refetch: refetchList,
    // ...restQuery
  } = useQuery(
    '/api/contents/find',
    { cntntsId },
    {
      cacheTime: 0,
      enabled: !detail,
      select: res => {
        return res.result;
      },
    },
  );

  useEffect(() => {
    if (data) {
      const head = document?.head;
      const link = document.createElement('link');

      link.type = 'text/css';
      link.rel = 'stylesheet';
      link.href = `${process.env.REACT_APP_STORAGE_URI}${data.cssUrl}`;

      head.appendChild(link);

      return () => {
        head.removeChild(link);
      };
    }
  }, [data]);

  useEffect(() => {
    if (menues && menues.length > 0) {
      const menu = getTreeMenu(menues, cntntsId);
      if (menu) {
        dispatch(setMenu(menu));
      }
    }
  }, [menues, cntntsId, dispatch]);

  const props = {
    data,
  };

  return <ContentsView {...props} />;
}

export default Contents;
