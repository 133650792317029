import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { useQuery } from '../../../hooks/useQuery';
import { setMenu } from '../../../store/menu/reducer';
import { getTreeMenu } from '../../../config/utils/util';
import {
  SITE_ID,
  SESSION_STORAGE,
  SEARCH_KEY,
} from '../../../config/constants';

import BoardView from './BoardView';

const BBS_API_URL = '/api/article/findAll';

/**
 * 공지사항 목록
 */
function Board(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menues = useSelector(state => state.menu.menues, shallowEqual);
  const { pathname, search: urlParam } = useLocation();
  const { mid: bbsId } = useParams();

  // get SESSION_STORAGE 정보를 가져온다.
  const [preSearchValue, setPreSearchValue] = useState();

  const [notiData, setNotiData] = useState();
  const [rowData, setRowData] = useState();
  // const [preSearchValue] = useState(() => {
  //   const searchValue = SESSION_STORAGE.getItem(SEARCH_KEY);

  //   return searchValue && JSON.parse(searchValue).bbsId === bbsId
  //     ? JSON.parse(searchValue)
  //     : null;
  // });

  const [search, setSearch] = useState({
    pageIndex: 1,
    pageSize: 8,
    recordCountPerPage: 8,
    searchCnd: '',
    searchWrd: '',
    bbsCode: '',
    bbsId,
    siteId: SITE_ID,
  });

  const [paging, setPaging] = useState();
  const [boardInfo, setBoardInfo] = useState({});

  // 검색 Option
  const [searchOption, setSearchOption] = useState({
    initialValues: {
      searchCnd: preSearchValue?.searchCnd ?? '',
      searchWrd: preSearchValue?.searchWrd ?? '',
    },
    items: [
      {
        name: 'searchWrd',
        placeholder: '검색어를 입력하세요.',
        type: 'input',
        codes: [],
      },
    ],
  });

  /**
   * TODO:
   * 마스터 코드 값을 통해 정보를 받아와서 게시판 정보를 뿌리는 형태를 구성
   */
  const [categoryOption, setCategoryOption] = useState({
    initialValues: {
      bbsCode: preSearchValue?.bbsCode ?? '',
    },
    categories: [
      { name: '전체', key: 'all' },
      { name: '공지', key: 'notice' },
      { name: '보도뉴스', key: 'news' },
    ],
  });

  /**
   * 1 순위 호출
   * API - 게시판 마스터 조회
   * get으로 받은 마스터 ID를 기준으로 정보를 먼저 받는다.
   * 게시판 유형, 게시판 카테고리 유형 등 정보를 먼저 받음.
   */
  const {
    data: masterInfo,
    refetch: refetchMaster,
    // ...restQuery
  } = useQuery(
    '/api/article/board/find',
    {
      siteId: SITE_ID,
      bbsId,
    },
    {
      cacheTime: 0,
      // enabled: !!bbsId && !!SITE_ID,
      enabled: !!search,
      onSuccess: res => {
        if (res) {
          if (res.bbsCtCodes) {
            setCategoryOption(preCategoryOption => {
              return {
                ...preCategoryOption,
                categories: [{ name: '전체', key: '' }].concat(
                  res.bbsCtCodes.map(m => {
                    return { name: m.codeNm, key: m.code };
                  }),
                ),
              };
            });
          } else {
            setCategoryOption({});
          }
        }
      },
    },
  );

  /**
   * 2 순위 호출
   * API - 게시글 조회
   * 게시판에서 받은 정보를 기준으로 게시글 호출
   */
  const {
    data,
    refetch: refetchList,
    // ...restQuery
  } = useQuery(
    // bbsId === 'BBSMSTR_000000000363' ? BBS_API_URL_ANNO : BBS_API_URL,
    BBS_API_URL,
    { ...search, bbsId },
    {
      cacheTime: 0,
      enabled: !!search,
      onSuccess: res => {
        if (res) {
          setPaging(res.paginationInfo);
          setBoardInfo({
            ...boardInfo,
            bbsTyCode: res?.boardInfo?.bbsTyCode ?? 'BBST02',
          });

          setRowData(res.resultList);
          setNotiData(res.noticeList);
        }
      },
    },
  );

  const handleSetSearch = obj => {
    setSearch(obj);
  };

  /**
   * 페이징 처리
   * @param {*} pageIndex
   * @param {*} pageSize
   */
  const handlePagination = (pageIndex, pageSize) => {
    handleSetSearch(preSearch => {
      const newSearch = { ...preSearch, pageIndex, pageSize };
      handleSearchValue(newSearch);
      return newSearch;
    });
  };

  /**
   * 검색 처리
   * @param {*} searchWord
   */
  const handleSearch = (event, searchBar) => {
    event.preventDefault();
    const newSearch = { ...searchBar, pageIndex: 1 };
    handleSearchValue(newSearch);
    handleSetSearch(() => {
      return { ...search, ...newSearch };
    });
  };

  /**
   * 카테고리 처리
   * @param {*} event 이벤트
   * @param {*} category 카테고리
   */
  const handleCategory = (event, category) => {
    event.preventDefault();
    const newSearch = { bbsCode: category, pageIndex: 1 };
    setCategoryOption({
      ...categoryOption,
      initialValues: { bbsCode: category },
    });
    handleSearchValue(newSearch);

    handleSetSearch(() => {
      return { ...search, ...newSearch };
    });
  };

  /**
   * SessionStorage에 페이지 검색 정보를 저장하는 이벤트
   * @search 변경 된 검색 정보
   */
  const handleSearchValue = newSearch => {
    SESSION_STORAGE.setItem(
      SEARCH_KEY,
      JSON.stringify({
        ...search,
        ...newSearch,
      }),
    );
  };

  const handleMoveDetail = url => {
    navigate(url);
  };

  useEffect(() => {
    const searchValue = JSON.parse(SESSION_STORAGE.getItem(SEARCH_KEY));

    let params = {
      pageIndex: 1,
      pageSize: 8,
      recordCountPerPage: 8,
      searchCnd: '',
      searchWrd: '',
      bbsCode: '',
    };

    if (searchValue && searchValue?.bbsId === bbsId) {
      params = {
        ...params,
        ...searchValue,
      };
    }
    if (
      (!!search && search?.bbsId !== bbsId) ||
      (searchValue && search?.bbsId === bbsId)
    ) {
      setSearch({
        ...params,
        bbsId,
        siteId: SITE_ID,
      });

      setSearchOption(preSearchOption => {
        return {
          ...preSearchOption,
          initialValues: {
            searchCnd: params?.searchCnd,
            searchWrd: params?.searchWrd,
          },
        };
      });

      setCategoryOption(preCategoryOption => {
        return {
          ...preCategoryOption,
          initialValues: {
            bbsCode: params?.bbsCode,
          },
        };
      });
    }
  }, [bbsId]);

  // 게시글 상세에서 목록 클릭시 컨텐츠 영역 고정
  useEffect(() => {
    if (urlParam.length > 0) {
      const element = document.getElementById('content');
      element?.scrollIntoView(false);
    }
  }, []);

  useEffect(() => {
    if (menues && menues.length > 0) {
      const menu = getTreeMenu(menues, bbsId);
      if (menu) {
        dispatch(setMenu(menu));
      }
    }
  }, [menues, bbsId, dispatch]);

  /**
   * props 세팅
   */
  const newProps = {
    menues,
    search,
    paging,
    boardInfo,
    /* notiList: data?.noticeList,
    dataList: data?.resultList, */
    notiList: notiData,
    dataList: rowData,
    searchOption,
    categoryOption,
    //    onSetSearch: handleSetSearch, // State 저장
    onSearch: handleSearch, // 검색 처리
    onCategory: handleCategory, // 카테고리 변경 처리
    onPaging: handlePagination, // 페이징 변경 처리
    onMoveDetail: handleMoveDetail,
    //    onSetStorage: handleSearchValue, // 검색 정보 저장 유지 용(Session Storage에 적용)
  };

  return <BoardView {...newProps} />;
}

export default Board;
