import React from 'react';
import styled from 'styled-components';

import SiteHeader from '../SiteHeader';
import SiteContainer from './SiteContainer';
import SiteFooter from '../SiteFooter';

function SiteLayoutView(props) {
  return (
    <SiteLayout className="site-layout">
      <SiteHeader {...props} />
      <SiteContainer {...props} />
      <SiteFooter {...props} />
    </SiteLayout>
  );
}

const SiteLayout = styled.div`
  min-height: 100vh;
`;

export default SiteLayoutView;
