import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { menuSelector } from '../../../store/menu/reducer';

import SiteLayoutView from './SiteLayoutView';

/**
 * 바능 CMS Demo 기본 레이아웃 구조
 * <SiteLayout>
 *   <SiteContainer>
 *     <SiteContent>
 *       {children}
 *     </SiteContent>
 *   </SiteContainer>
 * </SiteLayout>
 */
function SiteLayout(props) {
  const { menues } = props;
  // redux로 구성 된 menues, 선택 된 menu를 가져온다.
  const { menu } = useSelector(menuSelector, shallowEqual);

  /**
   * 선택 된 메뉴 기준 뎁스
   * 1 Depth Menu Object
   *   > 2 Depth Menu Object  <-- select
   *
   * 1 Depth Menu Object
   *   > 2 Depth Menu Object
   *     > 3 Depth Menu Object  <-- select
   *
   * 1 Depth 메뉴는 제공하지 않음
   */
  const [selMenu, setSelMenu] = useState({});

  /**
   * 메뉴 뎁스에 따른 1 Leaf 구조 생성
   * 1 Depth Menu Object
   *   > 2 Depth Menu Object
   *     > 3 Depth Menu Object
   * 형태 구성
   * @param {mn} menu 선택된 메뉴
   * @param {p} menu 하위 뎁스 메뉴로 재귀호출 시 상위와 나의 뎁스 메뉴를 같이 던진다.
   * @param {loopCnt} int 루프 카운터 확인 용
   * @return ㅇ-ㅇ(선택한 메뉴), ㅇ-ㅇ-ㅇ(선택한 메뉴) 형태의 object 반환
   */
  const handleSelectedMenu = useCallback(
    (mn, p = {}, loopCnt = 1) => {
      const obj = {
        depth: mn.depth,
        menuNo: mn.menuNo,
        menuNm: mn.menuNm,
        menuUrl: mn.menuUrl,
        imageFileId: mn.imageFileId,
        relateImagePath: mn.relateImagePath,
        children: mn.children,
        child: p,
      };

      let upper;
      // 메뉴의 Depth가 === 3이면(특수한 경우)
      if (mn.depth === 3) {
        // 메뉴 전체 트리를 까서
        menues.forEach(items => {
          // 첫번째 뎁스에서 2번째 위치의 Menu Object를 설정
          if (items.depth === 1) {
            // 첫번째 메뉴의 하위 메뉴들 중에서
            items.children.forEach(item => {
              // 메뉴 No === 선택된 메뉴의 Upper Menu No와 같은 경우
              if (item.menuNo === mn.upperMenuNo) {
                // upper에 해당 아이템을 지정하도록 함
                upper = item;
              }
            });
          }
        });
      } else {
        upper = { ...menues.filter(m => m.menuNo === mn.upperMenuNo)[0] };
      }

      // 그리고 선택된 메뉴의 상위 메뉴가 있으면
      if (upper?.depth) {
        // 다시 재귀 호출하여 Object Set을 구성
        const upperObj = handleSelectedMenu(upper, obj, loopCnt + 1);
        return upperObj;
      }

      return obj;
    },
    [menues],
  );

  /**
   * menu, menues의 값이 변경되면 선택 된 메뉴 기준 menues에서
   * 위치를 찾아 setSelMenu에 설정
   */
  useEffect(() => {
    if (menu) {
      if (menu.depth > 1) {
        setSelMenu(handleSelectedMenu(menu));
      }
    }
  }, [handleSelectedMenu, menu, menues]);

  const newProps = {
    ...props,
    menu,
    selMenu,
  };

  return <SiteLayoutView {...newProps} />;
}

SiteLayout.propTypes = {
  menues: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOf([PropTypes.string, PropTypes.number])),
  ),
};

SiteLayout.defaultProps = {
  menues: [],
};

export default SiteLayout;
