import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../../atoms/Button';
import CustomerIcon from '../../atoms/Icon/CustomerIcon';
import { getMenuObj } from '../../../config/utils/util';

/**
 * 상단 메뉴를 렌더링하기 위한 용도
 * @param {*} props
 * @returns
 */
function MenuView(props) {
  const { isMenu, menues, onSetGnbMenu, onOverGnb, onSearchClear, onSetMenu } =
    props;
  const [draw, setDraw] = useState(false);
  const [current, setCurrent] = useState(999);

  // 링크 주소를 위한 설정..
  const [inquiry, setInquiry] = useState({});

  const handleListItem = menues => {
    return (
      menues &&
      menues.length > 0 &&
      menues?.map((m, index) => {
        return (
          m.gnbAt === 'Y' &&
          m.depth < 3 && (
            <li className="list-item" key={m.menuNo}>
              <Link
                to={m.menuUrl}
                onClick={() => {
                  if (isMenu) {
                    onSetGnbMenu(false);
                    onOverGnb(false);
                  } else {
                    onSetGnbMenu(true);
                    onOverGnb(true);
                  }

                  onSearchClear();
                  m?.depth > 1 && onSetMenu(m);
                }}
                onKeyUp={e => {
                  if (e.key === 'Tab') {
                    e.preventDefault();
                    onSetGnbMenu(true);
                    onOverGnb(true);
                  }
                }}
              >
                <span>{m.menuNm}</span>
              </Link>
              {m.children?.length > 0 && (
                <div
                  className={
                    current === index
                      ? `depth${m.depth + 1} ${draw && 'open'}`
                      : `depth${m.depth + 1}`
                  }
                >
                  <ul className={`list--depth${m.depth + 1}`}>
                    {handleListItem(m.children)}
                  </ul>
                </div>
              )}
            </li>
          )
        );
      })
    );
  };

  return (
    <>
      <StyledNav id="gnb" className={`gnb ${isMenu ? 'gnb-show' : 'gnb-hide'}`}>
        <h2 className="sr-only">주메뉴</h2>
        <ul className="gnb-list list--depth1">{handleListItem(menues)}</ul>
        <Button
          type="button"
          className="btn-menu--close"
          size="xs"
          variant="link"
          onClick={e => {
            e.preventDefault();
            if (isMenu) {
              onOverGnb(false);
              onSetGnbMenu(false);
            } else {
              onOverGnb(true);
              onSetGnbMenu(true);
            }
          }}
        >
          <i className="ri-close-line" />
          <span className="sr-only">주메뉴 닫기</span>
        </Button>
        <div className="gnb-bg" />
      </StyledNav>
      {/* S::util */}
      {(() => {
        const menu = getMenuObj(menues, 9110017, 9110018);

        return (
          menu && (
            <div className="util">
              <Link
                to={menu?.menuUrl}
                className="custom"
                onClick={() => {
                  onSetMenu && onSetMenu(menu);
                }}
              >
                <span className="ico">
                  <CustomerIcon />
                </span>
                <span>상담신청</span>
              </Link>
            </div>
          )
        );
      })()}
      {/* E::util */}
    </>
  );
}

const StyledNav = styled.nav`
  /* GNB */
  z-index: 1;
  &.gnb-show {
    &::after {
      content: '';
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      background: var(--color-black-rgba-25);
      z-index: -1;
    }
    .gnb-list {
      .active {
        > a {
          color: var(--color-point-sub2);
          span {
            &::after {
              transform: scaleX(1);
            }
          }
        }
      }
    }
    .depth2 {
      opacity: 1;
      visibility: visible;
      pointer-events: visible;
      z-index: 1;
    }
  }
  .gnb-bg {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0px;
    width: 100vw;
    height: 0px;
    display: block;
    transform: translate(-50%, 100%);
    border-top: 1px solid #d3e0e9;
    background-color: var(--color-white);
    transition: height 0.4s, opacity 0.4s;
    box-shadow: 0px 8px 15px rgb(149 157 165 / 7%);
    opacity: 0;
  }
  @media ${({ theme }) => theme.media.lgMin} {
    display: flex;
    justify-content: center;
    flex: 1;
    text-align: center;
    &.gnb-show {
      &::after {
        top: 10rem;
      }
      .depth2 {
        ul {
          li {
            a {
              opacity: 1;
            }
          }
        }
      }
      .gnb-bg {
        opacity: 1;
        height: 16rem;
      }
    }
    .gnb-list {
      display: flex;
      justify-content: center;
      flex: 1;
      max-width: 82rem;
    }
    /* 1차메뉴 */
    .list--depth1 {
      > li {
        flex: 1;
        position: relative;
        > a {
          position: relative;
          display: block;
          font-size: 2rem;
          color: var(--color-white);
          font-weight: 600;
          text-align: center;
          transition: all 0.3s ease;
          &:hover {
            color: var(--color-point-sub2);
            font-size: 2.4rem;
            span {
              &::after {
                transform: scaleX(1);
              }
            }
          }
          span {
            position: relative;
            display: block;
            margin: 0 3rem;
            line-height: 9rem;
            &::after {
              content: '';
              position: absolute;
              bottom: 0px;
              left: 0px;
              right: 0px;
              display: block;
              height: 3px;
              transform: scaleX(0);
              background-color: var(--color-point-sub2);
              transition: all 0.3s ease 0s;
              z-index: 1;
            }
          }
        }
      }
    }
    /* 2차메뉴 */
    .depth2 {
      position: absolute;
      left: 50%;
      bottom: 0px;
      padding-top: 1.5rem;
      transform: translate(-50%, 100%);
      opacity: 0;
      transition: all 0.4s cubic-bezier(0.59, 0.01, 0.43, 1);
      visibility: hidden;
      pointer-events: none;
      /* overflow: hidden; */
      ul {
        margin-top: 1rem;
        height: 0;
        li {
          & + li {
            margin-top: 1rem;
          }
          a {
            color: var(--color-black);
            font-size: 1.6rem;
            opacity: 0;
            transition: all 0.2s;
            &:hover {
              color: var(--color-point-sub2);
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.lg} {
    visibility: hidden;
    flex-direction: column;
    position: fixed;
    top: 0;
    z-index: 50;
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    /* overflow-x: hidden;
    overflow-y: auto; */
    overflow: hidden;
    transition-property: visibility, right;
    transition-duration: 0.25s;
    transition-timing-function: linear;
    &.gnb-hide {
      visibility: hidden;
      right: -100%;
    }
    &.gnb-show {
      visibility: visible;
      right: 0;
      &::after {
        top: 8rem;
      }
    }
    .gnb-list {
      height: calc(100vh - 8rem);
      margin-top: 8rem;
      background: var(--color-white);
      overflow-y: auto;
    }
    .gnb-util {
      background: var(--color-white);
      [class^='gnb-util'] {
        display: flex;
        align-items: center;
        height: 4.2rem;
        padding: 0 1.6rem;
      }
      &__header {
      }
      &__list {
        justify-content: flex-end;
        margin-left: auto;
        background: var(--color-main);
        li {
          & + li {
            margin-left: 2rem;
          }
          a {
            font-size: 1.3rem;
            color: var(--color-white);
          }
        }
      }
    }
    /* 1차메뉴 */
    .list--depth1 {
      > li {
        &.active {
          > a {
            color: var(--color-main);
            background: var(--color-white);
            & + .depth2 {
              display: block;
            }
          }
        }
        /* &:not(.active) {
            > a {
              & + .depth2 {
                display: none;
              }
            }
          } */
        > a {
          display: block;
          padding: 1.4rem 2.4rem;
          color: var(--color-white) !important;
          font-size: 2rem;
          font-weight: 500;
          border-bottom: 1px solid var(--color-black-rgba-10);
          background: var(--color-main);
          transition: all 0.3s ease;
          &:hover {
            color: var(--color-white);
          }
        }
      }
    }
    /* 2차메뉴 */
    .depth2 {
      overflow-y: auto;
      padding: 0 2.4rem;
      background: var(--color-white);
      .list--depth2 {
        margin-top: 0.6rem;
        > li {
          &.active {
            > a {
              color: var(--color-main);
              border-bottom-color: var(--color-main);
            }
          }
          &.has-sub {
            > a {
              &::after {
                content: '';
                display: inline-block;
                position: absolute;
                top: 45%;
                right: 0;
                width: 0.6rem;
                height: 0.6rem;
                border-left: 2px solid var(--color-gray-969696);
                border-bottom: 2px solid var(--color-gray-969696);
                transform: rotate(-45deg) translateY(-50%);
                vertical-align: top;
                transition: all 0.3s ease 0s;
              }
              &:hover {
                &::after {
                  border-left-color: var(--color-gray-3c3c3c);
                  border-bottom-color: var(--color-gray-3c3c3c);
                }
              }
            }
            &.active {
              > a {
                &::after {
                  border-left-color: var(--color-black);
                  border-bottom-color: var(--color-black);
                }
              }
            }
          }
          > a {
            position: relative;
            display: block;
            margin: 0;
            padding: 1.2rem 0;
            color: var(--color-black);
            font-size: 1.8rem;
            font-weight: 500;
            transition: all 0.35s ease;
            z-index: 10;
            /* border-bottom: 1px solid var(--color-gray-eaeaea); */
          }
        }
      }
    }
    .btn-menu--close {
      position: absolute;
      top: 1rem;
      right: -0.5rem;
      font-size: 4.5rem;
      line-height: 1;
      i {
        vertical-align: top;
      }
    }
  }
`;

MenuView.propTypes = {
  isMenu: PropTypes.bool,
  menues: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOf([PropTypes.string, PropTypes.number])),
  ),
  onSetGnbMenu: PropTypes.func,
  onOverGnb: PropTypes.func,
  onSearchClear: PropTypes.func,
  onSetMenu: PropTypes.func,
};

MenuView.defaultProps = {
  isMenu: false,
  menues: [],
  onSetGnbMenu: () => {},
  onOverGnb: () => {},
  onSearchClear: () => {},
  onSetMenu: () => {},
};

export default MenuView;
