const color = {};

const mediaSize = {
  xs: '', // Extra small devices (portrait phones)
  sm: '576px', // Small devices (landscape phones)
  smMin: '577px', // Medium devices (tablets)
  md: '768px', // Medium devices (tablets)
  mdMin: '769px', // Medium devices (tablets)
  lg: '992px', // Large devices (desktops)
  lgMin: '993px', // Large devices (desktops)
  xl: '1200px', // Extra large devices (large desktops)
  xlMin: '1201px', // Extra large devices (large desktops)
  xxl: '1400px', // Extra extra large devices (large desktops)
  xxlMin: '1401px', // Extra extra large devices (large desktops)
  // xxl: '1600px',
};

const media = {
  xs: `all and (max-width: ${mediaSize.xs})`,
  sm: `all and (max-width: ${mediaSize.sm})`,
  md: `all and (max-width: ${mediaSize.md})`,
  lg: `all and (max-width: ${mediaSize.lg})`,
  xl: `all and (max-width: ${mediaSize.xl})`,
  xxl: `all and (max-width: ${mediaSize.xxl})`,

  smMin: `all and (min-width: ${mediaSize.smMin})`,
  mdMin: `all and (min-width: ${mediaSize.mdMin})`,
  lgMin: `all and (min-width: ${mediaSize.lgMin})`,
  xlMin: `all and (min-width: ${mediaSize.xlMin})`,
  xxlMin: `all and (min-width: ${mediaSize.xxlMin})`,

  smMinmd: `all and (min-width:${mediaSize.smMin}) and (max-width:${mediaSize.md}) `,
  smMinlg: `all and (min-width:${mediaSize.smMin}) and (max-width:${mediaSize.lg}) `,
  smMinxl: `all and (min-width:${mediaSize.smMin}) and (max-width:${mediaSize.xl}) `,
  mdMinlg: `all and (min-width:${mediaSize.mdMin}) and (max-width:${mediaSize.lg}) `,
  mdMinxl: `all and (min-width:${mediaSize.mdMin}) and (max-width:${mediaSize.xl}) `,
  lgMinxl: `all and (min-width:${mediaSize.lgMin}) and (max-width:${mediaSize.xl}) `,
  lgMinxxl: `all and (min-width:${mediaSize.lgMin}) and (max-width:${mediaSize.xxl}) `,
  xlMinxxl: `all and (min-width:${mediaSize.xlMin}) and (max-width:${mediaSize.xxl}) `,
};

const text = {
  textOverflow: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  // textLineClamp2: {
  //   display: '-webkit-box',
  //   -webkitBoxOrient: 'vertical',
  //   -webkitLineClamp: '2',
  //   textOverflow: 'ellipsis',
  //   overflow: 'hidden',
  // },
};

const theme = { color, mediaSize, media, text };

export default theme;
