/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import CustomSwiper from '../../molecules/CustomSwiper/CustomSwiper';
import noImg from '../../../assets/img/common/no_img.jpg';
import { getMenuObj } from '../../../config/utils/util';

function MainView({
  menues,
  popups,
  banners,
  promotion,
  sidework,
  onSetMenu,
  onGetMenuObj,
}) {
  const swiperRef1 = useRef();
  const swiperRef2 = useRef();

  const [prev, setPrev] = useState(1);
  const [total, setTotal] = useState(1);
  // 커스텀 스와이프에 전달하기 위한 slider 목록
  const breakpoints = {
    576: {
      slidesPerView: 2,
      spaceBetween: 16,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 22,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 25,
    },
    1201: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
    1401: {
      slidesPerView: 4,
      spaceBetween: 35,
    },
  };

  return (
    <MainContainer id="content">
      <div className="section section-visual">
        <div className="inner-wrap">
          <video
            autoPlay
            loop
            playsInline
            muted
            className="video"
            width="100%"
            height="100%"
            poster=""
          >
            <source
              src="https://videos.pexels.com/video-files/5752729/5752729-uhd_2560_1440_30fps.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div className="scroll-down">
          <p>SCROLL</p>
          <div className="scroll-down__bg">
            <div className="scroll-down__bar" />
          </div>
        </div>
      </div>
      <div className="section section-news">
        <div className="inner-wrap">
          <div className="inner-container">
            <h3>보도자료</h3>
            <div className="news">
              <CustomSwiper
                ref={swiperRef2}
                {...{
                  sliders: sidework?.resultList?.map(m => {
                    return (
                      <Link
                        to={`/board/${m.bbsId}/detail/${m.nttId}`}
                        className="item"
                        onClick={() =>
                          onSetMenu && onSetMenu(onGetMenuObj(9110014, 9110015))
                        }
                      >
                        <div
                          className="thumb"
                          // style={{
                          //   backgroundImage: `url(${process.env.REACT_APP_STORAGE_URI}${slider?.filePath})`,
                          // }}
                        >
                          <div>
                            {m?.filePath?.length > 0 ? (
                              <img
                                src={`${process.env.REACT_APP_STORAGE_URI}${m?.filePath}`}
                                alt={m.nttSj}
                              />
                            ) : (
                              <img src={noImg} alt={m?.nttSj} />
                            )}
                          </div>
                        </div>
                        <div className="desc-box">
                          <div className="desc">
                            {m?.frstRegisterPnttm && (
                              <span>{m.frstRegisterPnttm}</span>
                            )}
                            <strong>{m.nttSj}</strong>
                            {/* {slider?.content && <span>{slider.content}</span>} */}
                          </div>
                        </div>
                      </Link>
                    );
                  }),
                  setPrev,
                  setTotal,
                  breakpoints,
                  onSetMenu, // 불필요
                  selectedMenuObj: onGetMenuObj(9110014, 9110015), // 불필요
                }}
              />
              {(() => {
                const menu = getMenuObj(menues, 9110014, 9110015);

                return (
                  menu && (
                    <Link
                      className="btn-more"
                      to={menu?.menuUrl}
                      onClick={() => {
                        onSetMenu && onSetMenu(menu);
                      }}
                    >
                      MORE <i className="ri-add-fill" />
                    </Link>
                  )
                );
              })()}
            </div>
          </div>
        </div>
      </div>
      <div className="section section-promotion">
        <div className="inner-wrap">
          <div className="inner-container">
            <h3>홍보자료</h3>
            <div className="promotion">
              <CustomSwiper
                ref={swiperRef1}
                {...{
                  sliders: promotion?.resultList?.map(m => {
                    return (
                      <Link
                        to={`/board/${m.bbsId}/detail/${m.nttId}`}
                        className="item"
                        onClick={() =>
                          onSetMenu && onSetMenu(onGetMenuObj(9110014, 9110016))
                        }
                      >
                        <div
                          className="thumb"
                          // style={{
                          //   backgroundImage: `url(${process.env.REACT_APP_STORAGE_URI}${slider?.filePath})`,
                          // }}
                        >
                          <div>
                            {m?.filePath?.length > 0 ? (
                              <img
                                src={`${process.env.REACT_APP_STORAGE_URI}${m?.filePath}`}
                                alt={m.nttSj}
                              />
                            ) : (
                              <img src={noImg} alt={m?.nttSj} />
                            )}
                          </div>
                        </div>
                        <div className="desc-box">
                          <div className="desc">
                            {m?.frstRegisterPnttm && (
                              <span>{m.frstRegisterPnttm}</span>
                            )}
                            <strong>{m.nttSj}</strong>
                            {/* {slider?.content && <span>{slider.content}</span>} */}
                          </div>
                        </div>
                      </Link>
                    );
                  }),
                  setPrev,
                  setTotal,
                  breakpoints,
                  onSetMenu,
                  selectedMenuObj: onGetMenuObj(9110014, 9110016),
                }}
              />
              {(() => {
                const menu = getMenuObj(menues, 9110014, 9110016);

                return (
                  menu && (
                    <Link
                      className="btn-more"
                      to={menu?.menuUrl}
                      onClick={() => {
                        onSetMenu && onSetMenu(menu);
                      }}
                    >
                      MORE <i className="ri-add-fill" />
                    </Link>
                  )
                );
              })()}
            </div>
          </div>
        </div>
      </div>
    </MainContainer>
  );
}

const MainContainer = styled.section`
  overflow-x: hidden;
  .inner-container {
    position: relative;
    max-width: var(--cantainer-width);
    margin: 0 auto;
  }
  h3 {
    position: relative;
    margin-bottom: 1rem;
    font-size: 3.2rem;
  }
  .info-txt {
    color: var(--color-white);
    font-size: 2.5rem;
    font-weight: 600;
    letter-spacing: -0.5px;
  }
  .c-visual {
    height: 100vh;
    background: #eee;
    &__text {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: var(--color-white);
      .text-title {
        line-height: 1;
        p {
          font-family: var(--font-GmarketSans);
          font-size: 9rem;
          font-weight: 700;
        }
        span {
          display: block;
          font-family: var(--font-Montserrat);
          font-size: 8.4rem;
          font-weight: 500;
          text-transform: uppercase;
          letter-spacing: -0.25px;
        }
      }
      .text-sub {
        font-size: 2.4rem;
        font-weight: 500;
      }
    }
  }
  .section {
    &-visual {
      position: relative;
      .inner-wrap {
        position: relative;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        video {
          object-fit: cover;
          max-height: calc(100vh);
        }
      }
    }
    /* 보도자료 & 홍보자료 */
    &-news,
    &-promotion {
      .info-txt {
        color: var(--color-black);
        margin-bottom: 9rem;
      }
      .news,
      .promotion {
        position: relative;
        .btn-more {
          position: absolute;
          top: -4rem;
          right: 0;
          font-family: var(--font-Montserrat);
          font-size: 1.4rem;
          font-weight: 600;
          i {
            font-size: 1.8rem;
          }
        }
      }
    }
    &-footer {
      .inner-wrap {
      }
    }
  }
  @media ${({ theme }) => theme.media.xxlMin} {
    margin-bottom: 10rem;
    .section {
      & + .section {
        margin-top: 12rem;
      }
      &-news,
      &-promotion {
        .inner-wrap {
          padding: 0 6.4rem;
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.lgMinxxl} {
    margin-bottom: 10rem;
    .section {
      & + .section {
        margin-top: 12rem;
      }
      &-news,
      &-promotion {
        .inner-wrap {
          padding: 0 3.2rem;
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.lgMin} {
    .scroll-down {
      position: absolute;
      left: 4rem;
      bottom: 8rem;
      transform: translateX(-50%);
      color: var(--color-white);
      p {
        margin-bottom: 0.8rem;
        font-family: var(--font-Montserrat);
        font-size: 0.8rem;
        font-weight: 700;
        letter-spacing: -0.25px;
      }
      &__bg {
        position: relative;
        left: 50%;
        width: 2px;
        height: 3rem;
        background-color: var(--color-white-rgba-25);
      }
      &__bar {
        position: relative;
        left: 50%;
        /* left: calc(50% + 0.5px); */
        transform: translateX(-50%);
        width: 2px;
        height: 1.5rem;
        background-color: var(--color-white);
        animation: move-down 1.2s both linear infinite;
        @keyframes move-down {
          0% {
            opacity: 0;
            transform: translateY(0) translateX(-1px);
          }
          35% {
            opacity: 1;
            transform: translateY(0.75rem) translateX(-1px);
          }
          70% {
            opacity: 1;
            transform: translateY(1.2rem) translateX(-1px);
          }
          100% {
            opacity: 0;
            transform: translateY(1.75rem) translateX(-1px);
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.lg} {
    margin-bottom: 6rem;
    .section {
      & + .section {
        margin-top: 4rem;
      }
      &-news,
      &-promotion {
        .inner-wrap {
          padding: 0 2.4rem;
        }
      }
    }
    .scroll-down {
      display: none;
    }
  }
  @media ${({ theme }) => theme.media.smMinlg} {
    /* .section {
      &-visual {
        .inner-wrap {
          height: 45rem;
        }
      }
    } */
  }
  @media ${({ theme }) => theme.media.sm} {
    /* .section {
      &-visual {
        .inner-wrap {
          height: 35rem;
        }
      }
    } */
  }
`;

MainView.propTypes = {
  menues: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  popups: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  banners: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  promotion: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  sidework: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  onSetMenu: PropTypes.func,
  onGetMenuObj: PropTypes.objectOf,
};

MainView.defaultProps = {
  menues: [],
  popups: [],
  banners: [],
  promotion: [],
  sidework: [],
  onSetMenu: () => {},
  onGetMenuObj: {},
};

export default MainView;
