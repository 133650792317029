/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FooterLogoImg from '../../../assets/img/common/footer-logo.png';
import Wow330LogoImg from '../../../assets/img/common/logo-wow330.png';
import { getMenuObj } from '../../../config/utils/util';

/**
 * Footer
 * @returns
 */
function SiteFooterView(props) {
  const { menues, selMenu, onSetMenu, pageType } = props;
  // scrollToTop
  const [showButton, setShowButton] = useState(false);
  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    const handleShowButton = () => {
      if (window.scrollY > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
    window.addEventListener('scroll', handleShowButton);
    return () => {
      window.removeEventListener('scroll', handleShowButton);
    };
  }, []);

  return (
    <StyledFooter className="site-layout__footer">
      <div className="c-footer">
        <div className="c-footer__con">
          <div className="inner-con">
            <span className="c-footer__logo">
              <img src={FooterLogoImg} alt="바능 CMS Demo" />
            </span>
            <div className="c-footer__nav">
              <ul>
                {menues?.length > 0 &&
                  menues?.map(m => {
                    return (
                      m.gnbAt === 'Y' && (
                        <li
                          className={`${
                            pageType !== 'main' && m.menuNo === selMenu?.menuNo
                              ? 'active'
                              : ''
                          }`}
                        >
                          <span>{m.menuNm}</span>
                        </li>
                      )
                    );
                  })}
              </ul>
            </div>
          </div>
          {/*
          <a
            href="http://auroraflow.kr/"
            target="_blank"
            title="새창열림"
            rel="noopener noreferrer"
          >
            <div className="logo-wow330">
              <img src={Wow330LogoImg} alt="wow330" />
              <span className="sr-only">wow330</span>
            </div>
          </a>
          */}
        </div>
        {/*
        <div className="c-footer__office">
          <address>
            <ul className="item-list">
              <li>
                <strong>을지로본점</strong>
                <span className="address">
                  서울 중구 동호로 347 (오장동) <br />
                  제이디타워 3층~5층
                </span>
                <span className="tel">Tel : 02-2183-1700</span>
                <span className="fax">Fax : 02-2265-2004</span>
              </li>
              <li>
                <strong>여의도지사</strong>
                <span className="address">
                  서울 영등포구 국회대로70길 23 <br />
                  용산빌딩 9층 (여의도동)
                </span>
                <span className="tel">Tel : 02-320-8281</span>
                <span className="fax">Fax : 02-761-8281</span>
              </li>
              <li>
                <strong>부평지사</strong>
                <span className="address">
                  인천 부평구 광장로 4 <br />
                  코아빌딩 7층, 10층 (부평동)
                </span>
                <span className="tel">Tel : 02-320-8321</span>
                <span className="fax">Fax : 02-505-7005</span>
              </li>
              <li>
                <strong>가산지사</strong>
                <span className="address">
                  서울 금천구 벚꽃로 286 삼성리더스타워 <br />
                  11층 1110호 1111호 (가산동)
                </span>
                <span className="tel">Tel : 02-2183-1352</span>
                <span className="fax">Fax : 02-855-7010</span>
              </li>
            </ul>
          </address>
        </div>
        */}
        <div className="c-footer__info">
          <ul>
            {(() => {
              const menuObjs = {
                menu1: getMenuObj(menues, 9110033, 9110035),
                menu2: getMenuObj(menues, 9110033, 9110037),
              };

              return (
                menuObjs &&
                Object.keys(menuObjs).map(m => {
                  return (
                    m && (
                      <li key={menuObjs[m]?.menuNo}>
                        <Link
                          to={menuObjs[m]?.menuUrl}
                          onClick={e => {
                            onSetMenu && onSetMenu(menuObjs[m]);
                          }}
                        >
                          {menuObjs[m]?.menuNm}
                        </Link>
                      </li>
                    )
                  );
                })
              );
            })()}
          </ul>
          <p className="copy">
            COPYRIGHT(C) 2022 (주)바능 CMS Demo. CO.LTD ALL RIGHT RESERVED.
          </p>
        </div>
        {showButton && (
          <div className="scroll-top">
            <button type="button" id="top" onClick={scrollToTop}>
              <i className="ri-arrow-up-line" />
              <span className="sr-only">상단으로 바로가기</span>
            </button>
          </div>
        )}
      </div>
    </StyledFooter>
  );
}

const StyledFooter = styled.footer`
  background: var(--color-gray-323232);
  .c-footer {
    position: relative;
    &__con {
      display: flex;
      justify-content: space-between;
      max-width: var(--cantainer-width);
      padding-right: 2.4rem;
      padding-left: 2.4rem;
      margin: 0 auto;
      .inner-con {
        display: flex;
        align-items: center;
      }
      .logo-wow330 {
        position: relative;
        padding-right: 2rem;
        max-width: 13.4rem;
        &::after {
          content: '';
          display: inline-block;
          position: absolute;
          top: calc(50% - 0.6rem);
          right: 6px;
          width: 0.8rem;
          height: 0.8rem;
          border-top: 2px solid #dcdcdc;
          border-left: 2px solid #dcdcdc;
          transform: rotate(135deg) translateY(-50%);
          vertical-align: top;
          transition: all 0.3s ease 0s;
        }
        img {
          width: 10.95rem;
        }
      }
    }
    &__logo {
      img {
        width: 12.8rem;
      }
    }
    /* footer 하단메뉴 */
    &__office {
      padding: 2.8rem 0;
      address {
        max-width: var(--cantainer-width);
        margin: 0 auto;
        padding: 0 2.4rem;
        color: var(--color-gray-a0a0a0);
        .item-list {
          li {
            strong {
              color: var(--color-white);
              font-weight: 500;
            }
            span {
              white-space: nowrap;
              &.address {
                font-size: 1.4rem;
              }
              &.tel,
              &.fax {
                font-size: 1.3rem;
                font-weight: 300;
              }
            }
          }
        }
      }
    }
    &__info {
      position: relative;
      max-width: var(--cantainer-width);
      margin: 0 auto;
      padding: 2.8rem 2.4rem;
      color: var(--color-gray-737373);
      ul {
        li {
          display: inline-block;
          & + li {
            margin-left: 4rem;
          }
          &:first-child {
            a {
              border-bottom: 1px solid var(--color-white-rgba-50);
            }
          }
          a {
            color: var(--color-white);
            font-size: 1.3rem;
          }
        }
      }
      .copy {
        font-size: 1.3rem;
      }
    }
    .scroll-top {
      position: fixed;
      animation: scroll-Up 0.4s both;
      z-index: 1;
      @-webkit-keyframes scroll-Up {
        0% {
          visibility: visible;
          opacity: 0;
          transform: translate3d(0, 30%, 0);
        }
        100% {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
      @keyframes scroll-Up {
        0% {
          visibility: visible;
          opacity: 0;
          transform: translate3d(0, 30%, 0);
        }
        100% {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
      }
      /* } */
      button {
        width: 6rem;
        height: 6rem;
        border-radius: 50%;
        background: var(--color-gray-3c3c3c);
        background: var(--color-white);
        box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.2);
        transition: all 0.3s;
        i {
          color: var(--color-white);
          color: var(--color-main);
          font-size: 2.6rem;
        }
      }
    }
  }
  /* 1201px 이상 */
  @media ${({ theme }) => theme.media.xlMin} {
    .c-footer {
      &__con {
        align-items: center;
      }
      &__nav {
        margin-left: 12.5rem;
        ul {
          li {
            display: inline-block;
            & + li {
              margin-left: 12rem;
            }
            &.active {
              span {
                color: var(--color-point-sub2);
              }
            }
            span {
              font-size: 1.7rem;
              color: var(--color-gray-a0a0a0);
              transition: all 0.3s ease;
              /* &:hover {
                color: var(--color-point-sub2);
              } */
            }
          }
        }
      }
      &__office {
        border-top: 1px solid var(--color-white-rgba-25);
        border-bottom: 1px solid var(--color-white-rgba-25);
        address {
          .item-list {
            display: flex;
            li {
              width: calc(100% / 4);
              padding: 1rem 0;
              vertical-align: top;
              text-align: center;
              & + li {
                border-left: 1px solid var(--color-white-rgba-25);
              }
              strong {
                display: block;
                margin-bottom: 2.4rem;
                font-size: 1.6rem;
              }
              span {
                display: block;
                &.address {
                  margin-bottom: 1.5rem;
                }
              }
            }
          }
        }
      }
      &__info {
        text-align: center;
      }
    }
  }
  /* 993px ~ 1200px */
  @media ${({ theme }) => theme.media.lgMinxl} {
    .c-footer {
      display: flex;
      flex-wrap: wrap;
      &__con {
        flex-direction: column;
        flex: 0 0 20rem;
      }
      &__nav {
        display: none;
      }
      &__info {
        flex: 1;
        border-top: 1px solid var(--color-white-rgba-25);
      }
    }
  }
  /* 769px ~ 1200px */
  @media ${({ theme }) => theme.media.mdMinxl} {
    .c-footer {
      &__office {
        address {
          .item-list {
            li {
              strong {
                flex: 0 0 8.4rem;
              }
            }
          }
        }
      }
    }
  }
  /* ~ 1200 */
  @media ${({ theme }) => theme.media.xl} {
    .c-footer {
      &__office {
        flex: 0 0 calc(100% - 20rem);
        address {
          position: relative;
          &::before {
            content: '';
            position: absolute;
            top: 4px;
            left: -1rem;
            bottom: 4px;
            width: 1px;
            background: var(--color-white-rgba-10);
          }
          .item-list {
            flex-direction: column;
            li {
              display: flex;
              align-items: center;
              & + li {
                margin-top: 1rem;
              }
              span:not(:last-child) {
                position: relative;
                margin-right: 1rem;
                padding-right: 1rem;
                &::before {
                  content: '';
                  position: absolute;
                  top: 4px;
                  right: 0;
                  bottom: 4px;
                  width: 1px;
                  background: var(--color-white-rgba-25);
                }
                br {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
  /* 993 ~ */
  @media ${({ theme }) => theme.media.lgMin} {
    .c-footer {
      &__con {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }
    }
  }
  /* ~ 992 */
  @media ${({ theme }) => theme.media.lg} {
    .c-footer {
      &__con {
        align-items: center;
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
      }
      &__nav {
        display: none;
      }
      &__office {
        border-top: 1px solid var(--color-white-rgba-25);
        border-bottom: 1px solid var(--color-white-rgba-25);
      }
    }
  }
  /* 769 ~ */
  @media ${({ theme }) => theme.media.mdMin} {
    .c-footer {
      &__info {
        ul {
          position: absolute;
          right: 2.4rem;
        }
      }
      .scroll-top {
        right: 3rem;
        bottom: 5rem;
      }
    }
  }
  /* ~ 768 */
  @media ${({ theme }) => theme.media.md} {
    .c-footer {
      &__office {
        address {
          .item-list {
            li {
              flex-wrap: wrap;
              strong {
                flex: 0 0 100%;
                font-size: 1.7rem;
              }
              span.address,
              span.tel,
              span.fax {
                font-size: 1.5rem;
              }
              span.address {
                display: block;
              }
            }
          }
        }
      }
      &__info {
        text-align: center;
        ul {
          position: initial;
          margin-bottom: 1rem;
        }
      }
      .scroll-top {
        right: 2rem;
        bottom: 3rem;
        z-index: 1;
      }
    }
  }
`;

SiteFooterView.propTypes = {
  menues: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOf([PropTypes.string, PropTypes.number])),
  ),
  selMenu: PropTypes.objectOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  ),
  pageType: PropTypes.string,
  onSetMenu: PropTypes.func,
};

SiteFooterView.defaultProps = {
  menues: [],
  selMenu: {},
  pageType: '',
  onSetMenu: () => {},
};

export default SiteFooterView;
