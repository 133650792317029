import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import HTMLRenderer from 'react-html-renderer';

function PolicyView({ data }) {
  return (
    <StyledContentContainer id="content">
      <article className="c-contents">
        <HTMLRenderer
          html={data?.useStplatCn}
          // components={{
          //   h1: props => <Heading color="red" {...props} />,
          //   h2: Subheading,
          //   a: Link,
          // }}
        />
      </article>
    </StyledContentContainer>
  );
}

const StyledContentContainer = styled.section``;

PolicyView.propTypes = {
  data: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
};

PolicyView.defaultProps = {
  data: {},
};

export default PolicyView;
