import React, { useState } from 'react';
import SiteContentView from './SiteContentView';

function SiteContent(props) {
  const [isClose, setIsClose] = useState(false);

  const handleToggle = () => {
    setIsClose(!isClose);
  };

  const handleSetClose = bool => {
    setIsClose(bool);
  };

  const newProp = {
    ...props,
    isClose,
    on3DepthMenuToggle: handleToggle,
    onSetClose: handleSetClose,
  };

  return <SiteContentView {...newProp} />;
}

export default SiteContent;
