import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getFileData } from '../../../../config/utils/util';

import SiteContent from './SiteContent';

function SiteContainerView(props) {
  const { selMenu } = props;

  const [isEnded, setIsEnded] = useState(false);

  const handleFinish = () => {
    setIsEnded(true);
  };

  // 1차 메뉴 PR인 경우 비디오 ended 이벤트를 처리하기 위한 목적
  useEffect(() => {
    if (selMenu?.menuNo) {
      setIsEnded(selMenu?.menuNo !== 9110014);
    }
  }, [selMenu]);

  return (
    <SubContainer>
      {!isEnded ? (
        <div className="section section-visual">
          <div
            className="inner-wrap"
            // style={{
            //   backgroundImage: `url(${
            //     getFileData(selMenu?.imageFileId, selMenu?.relateImagePath)[0]
            //       ?.url
            //   }`,
            // }}
            style={{
              backgroundImage: `url(https://images.pexels.com/videos/29789409/4k-drone-4k-nature-video-antalya-antique-29789409.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load)`,
            }}
          >
            <video
              autoPlay
              playsInline
              muted
              className="video"
              width="100%"
              height="100%"
              poster=""
              onEnded={handleFinish}
            >
              <source
                src="https://videos.pexels.com/video-files/29789409/12799550_1920_1080_30fps.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      ) : (
        <div
          className="sub-visual"
          style={{
            backgroundImage: `url(${
              getFileData(selMenu?.imageFileId, selMenu?.relateImagePath)[0]
                ?.url
            }`,
          }}
        >
          <div className="sub-visual__title">
            <h2>{selMenu?.menuNm}</h2>
          </div>
          <div className="scroll-down">
            <p>SCROLL</p>
            <div className="scroll-down__bg">
              <div className="scroll-down__bar" />
            </div>
          </div>
        </div>
      )}
      <SiteContent {...props} />
    </SubContainer>
  );
}

const SubContainer = styled.section`
  .sub-inner {
    &::before {
      content: '';
      display: inline-block;
      width: 100%;
      background: #333;
    }
    [class*='__title'] {
      max-width: var(--cantainer-width);
      width: 100%;
      margin: 0 auto;
      padding: 8rem 1.6rem 0;
      h2 {
        color: var(--color-black);
        font-size: 6.8rem;
        line-height: 1;
        text-transform: uppercase;
      }
    }
    & + .inner-container {
      [class*='__sub'] {
        h3 {
          margin-bottom: 0;
        }
      }
    }
  }
  .inner-wrap {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    video {
      display: block;
      object-fit: cover;
      max-height: calc(100vh);
    }
  }
  .sub-visual {
    display: flex;
    align-items: center;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    [class*='__title'] {
      position: relative;
      max-width: var(--cantainer-width);
      width: 100%;
      margin: 6rem auto 0;
      padding: 0 1.6rem;
      z-index: 1;
      h2 {
        color: var(--color-white);
        font-family: var(--font-GmarketSans);
        text-align: center;
        text-transform: uppercase;
      }
      p {
        color: var(--color-white);
        font-size: 2rem;
        line-height: 1.8;
        opacity: 0;
      }
    }
    .scroll-down {
      position: absolute;
      left: 50%;
      bottom: 2rem;
      transform: translateX(-50%);
      color: var(--color-white);
      display: none;
      p {
        margin-bottom: 0.8rem;
        font-size: 1.4rem;
        font-weight: 700;
      }
      &__bg {
        position: relative;
        left: 50%;
        width: 1px;
        height: 5rem;
        background-color: var(--color-white-rgba-25);
      }
      &__bar {
        position: relative;
        /* left: 50%; */
        left: calc(50% + 0.5px);
        transform: translateX(-50%);
        width: 1px;
        height: 2rem;
        background-color: var(--color-white);
        animation: move-down 1.2s both linear infinite;
        @keyframes move-down {
          0% {
            opacity: 0;
            transform: translateY(0) translateX(-1px);
          }
          35% {
            opacity: 1;
            transform: translateY(0.75rem) translateX(-1px);
          }
          70% {
            opacity: 1;
            transform: translateY(2rem) translateX(-1px);
          }
          100% {
            opacity: 0;
            transform: translateY(3rem) translateX(-1px);
          }
        }
      }
    }
  }

  @media ${({ theme }) => theme.media.lgMin} {
    /* .inner-wrap {
      min-height: 45rem;
    } */
    .sub-visual {
      height: 45rem;
    }
    .sub-inner {
      &::before {
        height: 9.5rem;
      }
      [class*='__title'] {
        text-align: center;
        p {
          margin-top: 2rem;
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.smMinlg} {
    /* .inner-wrap, */
    .sub-visual {
      height: 40rem;
    }
    .sub-inner {
      &::before {
        height: 6.8rem;
      }
    }
    /* .sub-visual {
      &__title {
        h2 {
          font-size: 7rem;
        }
      }
    } */
  }
  @media ${({ theme }) => theme.media.lg} {
    /* background: gray; */
    .sub-visual {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--color-black-rgba-25);
      }
      [class*='__title'] {
        p {
          padding: 0 4rem;
        }
      }
      .scroll-down {
        display: none;
      }
    }
  }
  @media ${({ theme }) => theme.media.smMin} {
    .sub-visual {
      [class*='__title'] {
        h2 {
          font-size: 6.5rem;
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.sm} {
    /* .inner-wrap, */
    .sub-visual {
      height: 35rem;
    }
    .sub-inner {
      &::before {
        height: 7.5rem;
      }
    }
    .sub-visual {
      &__title {
        h2 {
          font-size: 6.2rem;
        }
      }
    }
  }
`;

SiteContainerView.propTypes = {
  selMenu: PropTypes.objectOf(
    PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  ).isRequired,
};

export default SiteContainerView;
