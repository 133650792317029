import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactGA from 'react-ga4';

import React from 'react';
import { render, hydrate } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ScrollToTop from './config/utils/ScrollToTop';

import store from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './assets/less/theme.less';
import './index.css';

const rootElement = document.getElementById('root');

// 구글 애널리틱스 운영서버만 적용
if (process.env.REACT_APP_GOOGLE_ANALYTICS) {
  // 애널리틱스 적용
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS);
}

/** react-snap 적용 */
if (rootElement.hasChildNodes()) {
  hydrate(
    <BrowserRouter>
      <React.StrictMode>
        <Provider store={store}>
          <ScrollToTop />
          <App />
        </Provider>
      </React.StrictMode>
    </BrowserRouter>,
    rootElement,
  );
} else {
  render(
    <BrowserRouter>
      <React.StrictMode>
        <Provider store={store}>
          <ScrollToTop />
          <App />
        </Provider>
      </React.StrictMode>
    </BrowserRouter>,
    rootElement,
  );
}

// ConfigProvider.config({
//   prefixCls: 'custom',
//   theme: {
//     primaryColor: '#5b44fb',
//   },
// });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
