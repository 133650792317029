/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '../../atoms/Button';

import InquiryTit from '../../../assets/img/support/inquiry-tit.png';

function InquiryView({
  formik,
  valueEmailOptions,
  onChangeEmailOptions,
  onChangeNumberKey,
}) {
  return (
    <StyledArticle className="c-inquiry">
      <div className="c-inquiry__txt">
        <img src={InquiryTit} alt="" />
        <p>
          <strong>바능 CMS Demo</strong>이 새로운 <strong>멀티금융</strong>{' '}
          <strong>플랫폼</strong>을 열어갑니다.
        </p>
      </div>
      <div className="c-inquiry__form">
        <form
          id="inquiryForm"
          name="inquiryForm"
          onSubmit={formik.handleSubmit}
          className="inquiry-form"
        >
          <fieldset>
            <legend>상담 신청</legend>
            <div className="form-box">
              <h3>상담 신청</h3>
              <div className="inner-container__sub">
                <p className="title">개인정보처리방침</p>
                <div className="terms-box">
                  <div className="terms-scroll scrollBar-custom" tabIndex="0">
                    <div className="privacy__box">
                      <p className="tit">제1조 (개인정보 수집에 대한 동의)</p>
                      <p>
                        <strong>바능 CMS Demo</strong>(이하 회사)는 이용자들이
                        회사의 개인정보취급방침 또는 이용약관의 내용에 대하여
                        “동의”버튼 또는 “취소”버튼을 클릭할 수 있는 절차를
                        마련하여, “동의”버튼을 클릭하면 개인정보 수집에 대해
                        동의한 것으로 봅니다.
                      </p>
                    </div>
                    <div className="privacy__box">
                      <p className="tit">제2조 (개인정보 수집항목)</p>
                      <p>
                        온라인 문의를 통한 상담을 위해 처리하는 개인정보 항목은
                        아래와 같습니다.
                        <br />
                        수집항목 : 성명, 이메일, 전화번호
                      </p>
                    </div>
                    <div className="privacy__box">
                      <p className="tit">제3조 (개인정보의 이용목적)</p>
                      <p>
                        회사는 이용자의 사전 동의 없이는 이용자의 개인 정보를
                        공개하지 않으며, 원활한 고객상담, 각종 서비스의 제공을
                        위해 아래와 같이 개인정보를 수집하고 있습니다. 모든
                        정보는 상기 목적에 필요한 용도 이외로는 사용되지 않으며
                        수집 정보의 범위나 사용 목적, 용도가 변경될 시에는
                        반드시 사전 동의를 구할 것입니다.
                      </p>
                      <ul className="ul__list">
                        <li>성명 : 제품상담에 따른 본인 확인</li>
                        <li>
                          이메일, 전화번호 : 제품상담 및 이벤트 관련 고지사항
                          전달, 새로운 서비스 및 신상품 정보 제공(DM, SMS,
                          이메일 등 이용)
                        </li>
                      </ul>
                      <p>
                        이용자는 개인정보의 수집/이용에 대한 동의를 거부할 수
                        있습니다. 다만, 동의를 거부하는 경우 온라인 문의를 통한
                        상담은 불가하며 서비스 이용 및 혜택 제공에 제한을 받을
                        수 있습니다.
                      </p>
                    </div>
                    <div className="privacy__box">
                      <p className="tit">제4조 (개인정보의 보유 및 이용기간)</p>
                      <p>
                        원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는
                        해당 정보를 지체 없이 파기합니다.
                        <br />
                        그리고 상법, 전자상거래 등에서의 소비자보호에 관한 법률
                        등 관계법렵의 규정에 의하여 보존할 필요가 있는 경우
                        회사는 관계법령에서 정한 일정한 기간 동안 정보를
                        보관합니다.
                        <br />이 경우 회사는 보관하는 정보를 그 보관의
                        목적으로만 이용하며 보존기간은 아래와 같습니다.
                      </p>
                      <ul className="ul__list">
                        <li>
                          계약 또는 청약철회 등에 관한 기록 :
                          5년(전자상거래등에서의 소비자보호에 관한 법률)
                        </li>
                        <li>
                          소비자의 불만 또는 분쟁처리에 관한 기록 :
                          3년(전자상거래등에서의 소비자 보호에 관한 법률)
                        </li>
                        <li>
                          시용정보의 수집/처리 및 이용 등에 관한 기록 :
                          3년(신용정보의 이용 및 보호에 관한 법률)
                        </li>
                        <li>
                          회사는 귀중한 이용자의 개인정보를 안전하게 처리하며,
                          유출의 방지를 위하여 다음과 같은 방법을 통하여
                          개인정보를 파기합니다.
                        </li>
                        <li>
                          종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을
                          통하여 파기합니다.
                        </li>
                        <li>
                          전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수
                          없는 기술적 방법을 사용하여 삭제합니다
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="terms-check">
                  <input
                    id="checkPrivacy"
                    type="checkbox"
                    name="checkPrivacy"
                    // value={formik.values.checkPrivacy}
                    checked={formik.values.checkPrivacy}
                    onChange={formik.handleChange}
                  />
                  <label htmlFor="checkPrivacy">
                    개인정보처리방침에 동의합니다. <span>(필수)</span>
                  </label>
                </div>
                <p className="title">상담내용 작성</p>
                <div className="inquiry-form__box">
                  <div className="inquiry-form__item col2">
                    <div className="item-label required">구분</div>
                    <div className="item-info">
                      <span>
                        <input
                          id="cttSeCd-1"
                          name="cttSeCd"
                          type="radio"
                          value="CTTCD001"
                          checked={formik.values.cttSeCd === 'CTTCD001'}
                          onChange={formik.handleChange}
                        />
                        <label htmlFor="cttSeCd-1">일반문의</label>
                      </span>
                      {/* <span>
                        <input
                        id="cttSeCd-2"
                        name="cttSeCd"
                        type="radio"
                        value="CTTCD002"
                        checked={formik.values.cttSeCd === 'CTTCD002'}
                        onChange={formik.handleChange}
                      />
                      <label htmlFor="cttSeCd-2">견적문의</label>
                      </span> */}
                      <span>
                        <input
                          id="cttSeCd-3"
                          name="cttSeCd"
                          type="radio"
                          value="CTTCD003"
                          checked={formik.values.cttSeCd === 'CTTCD003'}
                          onChange={formik.handleChange}
                        />
                        <label htmlFor="cttSeCd-3">방문문의</label>
                      </span>
                    </div>
                  </div>
                  <div className="inquiry-form__item col2">
                    {/* <label htmlFor="contactor" className="required"> */}
                    <label htmlFor="contactor" className="required">
                      작성자
                    </label>
                    <input
                      type="text"
                      id="contactor"
                      name="contactor"
                      maxLength="100"
                      onChange={formik.handleChange}
                      value={formik.values.contactor}
                      placeholder="이름을 입력해주세요."
                    />
                  </div>
                  <div className="inquiry-form__item col2">
                    <div className="item-label">연락처</div>
                    <div className="item-info2">
                      <input
                        id="phoneNumber1"
                        name="phoneNumber1"
                        type="text"
                        maxLength="3"
                        onChange={formik.handleChange}
                        value={formik.values.phoneNumber1}
                        onKeyUp={onChangeNumberKey}
                      />
                      <input
                        id="phoneNumber2"
                        name="phoneNumber2"
                        type="text"
                        maxLength="4"
                        onChange={formik.handleChange}
                        value={formik.values.phoneNumber2}
                        onKeyUp={onChangeNumberKey}
                      />
                      <input
                        id="phoneNumber3"
                        name="phoneNumber3"
                        type="text"
                        maxLength="4"
                        onChange={formik.handleChange}
                        value={formik.values.phoneNumber3}
                        onKeyUp={onChangeNumberKey}
                      />
                    </div>
                  </div>
                  <div className="inquiry-form__item col2">
                    <div className="item-label required">이메일</div>
                    <div className="item-info2">
                      <input
                        type="text"
                        name="email1"
                        maxLength="80"
                        onChange={formik.handleChange}
                        value={formik.values.email1}
                      />
                      @
                      <input
                        type="text"
                        name="email2"
                        maxLength="20"
                        onChange={formik.handleChange}
                        value={formik.values.email2}
                      />
                      <select
                        id="emailOptions"
                        onChange={onChangeEmailOptions}
                        value={valueEmailOptions}
                      >
                        <option value="">직접입력</option>
                        <option value="gmail.com">gmail.com</option>
                        <option value="naver.com">naver.com</option>
                        <option value="hanmail.net">hanmail.net</option>
                        <option value="daum.net">daum.net</option>
                      </select>
                    </div>
                  </div>
                  <div className="inquiry-form__item">
                    <label htmlFor="cttSj" className="required">
                      제목
                    </label>
                    <input
                      type="text"
                      id="cttSj"
                      name="cttSj"
                      // maxLength=2000" 최대가 2000이지만 제목이라 제한함
                      maxLength="300"
                      value={formik.values.cttSj}
                      placeholder="제목을 입력해 주세요."
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="inquiry-form__item">
                    <label htmlFor="words" className="required">
                      문의사항
                    </label>
                    <textarea
                      rows={10}
                      id="words"
                      name="words"
                      onChange={formik.handleChange}
                      value={formik.values.words}
                      placeholder="내용을 입력해 주세요.(필수)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="c-inquiry__btn">
              <Button type="submit" size="lg" variant="default">
                상담 신청하기
              </Button>
            </div>
          </fieldset>
        </form>
      </div>
    </StyledArticle>
  );
}

const StyledArticle = styled.article`
  .c-inquiry {
    &__txt {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        font-size: 5.6rem;
        font-weight: 200;
        text-align: center;
        line-height: 1.3;
      }
    }
    &__form {
      .inquiry-form {
        > .inner-container__sub {
          padding-top: 0;
          padding-bottom: 0;
        }
        .terms-box {
          padding: 3rem 3.2rem;
          border: 1px solid #707070;
          background: #fafafa;
          .terms-scroll {
            max-height: 23rem;
            padding-right: 2rem;
            overflow-y: auto;
          }
        }
        .terms-check {
          margin-top: 2rem;
          text-align: center;
          font-size: 1.8rem;
          span {
            color: var(--color-red-FF3200);
          }
        }
        .form-box {
          background: var(--color-gray-F6F8FC);
          h3 {
            margin-bottom: 1.6rem;
          }
          .title {
            margin-bottom: 4rem;
            padding-bottom: 1rem;
            font-size: 2rem;
            font-weight: 600;
            border-bottom: 2px solid var(--color-gray-737373);
          }
        }
        &__box {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }
        &__item {
          flex: 0 0 100%;
          .item-label,
          label {
            display: inline-block;
            color: var(--color-black);
            /* font-size: 1.6rem; */
            font-weight: 600;
            letter-spacing: -0.065em;
            margin-bottom: 1.3rem;
            &.required {
              &::after {
                content: '*';
                margin-left: 5px;
                color: var(--color-red-FF3200);
              }
            }
          }
          .item-label {
            display: block;
          }
          .item-info {
            height: 5.5rem;
            padding: 1.7rem 2rem;
            background: #e6e9ec;
            span {
              & + span {
                margin-left: 8rem;
              }
              label {
                margin-bottom: 0;
              }
            }
          }
          .item-info2 {
            display: flex;
            align-items: center;
            gap: 1.6rem;
          }
          input,
          textarea,
          select {
            width: 100%;
            padding: 1.4rem 2rem;
            font-size: 1.5rem;
          }
          textarea {
            line-height: 1.5;
          }
        }
      }
    }
    &__btn {
      margin-top: 6rem;
      text-align: center;
      a {
        padding-right: 10rem;
        padding-left: 10rem;
        font-size: 1.7rem;
        font-weight: 500;
      }
    }
  }
  @media ${({ theme }) => theme.media.xxlMin} {
    .c-inquiry {
      &__form {
        .inquiry-form {
          &__box {
            gap: 3rem 8rem;
          }
          &__item {
            &[class*='col2'] {
              flex: 0 0 calc(50% - 5rem);
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.lgMin} {
    .c-inquiry {
      margin-bottom: 20rem;
      &__txt {
        margin: 10rem 0;
        img {
          max-width: 30rem;
        }
        p {
          max-width: 80rem;
          margin-top: 5rem;
        }
      }
      &__form {
        .inquiry-form {
          .terms-check {
            & + .title {
              margin-top: 8rem;
            }
          }
          .form-box {
            padding: 8rem 0;
          }
        }
      }
    }
  }

  @media ${({ theme }) => theme.media.lgMinxxl} {
    .c-inquiry {
      &__form {
        .inquiry-form {
          &__box {
            gap: 4rem;
          }
          &__item {
            &[class*='col2'] {
              flex: 0 0 calc(50% - 2rem);
            }
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.lg} {
    .c-inquiry {
      margin-bottom: 8rem;
      &__txt {
        margin: 7rem 0;
        img {
          max-width: 30rem;
        }
        p {
          margin: 4rem 2rem 0;
        }
      }
      &__form {
        .inquiry-form {
          .terms-check {
            & + .title {
              margin-top: 5rem;
            }
          }
          .form-box {
            padding: 6rem 0;
          }
          &__item {
            & + [class*='__item'] {
              margin-top: 2rem;
            }
          }
        }
      }
    }
  }
`;

export default InquiryView;

InquiryView.propTypes = {
  formik: PropTypes.shape({}),
  valueEmailOptions: PropTypes.string,
  onChangeEmailOptions: PropTypes.func,
};

InquiryView.defaultProps = {
  formik: {},
  valueEmailOptions: '',
  onChangeEmailOptions: null,
};
