import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import { menuSelector } from '../../../store/menu/reducer';
import useStoreMenu from '../../../hooks/useStoreMenu';

import MainLayoutView from './MainLayoutView';

function MainLayout(props) {
  const { menues } = useStoreMenu();

  // redux로 구성 된 menues, 선택 된 menu를 가져온다.
  const { menu } = useSelector(menuSelector, shallowEqual);

  const [selMenu, setSelMenu] = useState({});

  /**
   * 메뉴 뎁스에 따른 1 Leaf 구조 생성
   * 1 Depth Menu Object
   *   > 2 Depth Menu Object
   *     > 3 Depth Menu Object
   * 형태 구성
   * @param {mn} menu 선택된 메뉴
   * @param {p} menu 하위 뎁스 메뉴로 재귀호출 시 상위와 나의 뎁스 메뉴를 같이 던진다.
   * @param {loopCnt} int 루프 카운터 확인 용
   * @return ㅇ-ㅇ(선택한 메뉴), ㅇ-ㅇ-ㅇ(선택한 메뉴) 형태의 object 반환
   */
  const handleSelectedMenu = useCallback(
    (mn, p = {}, loopCnt = 1) => {
      const obj = {
        depth: mn.depth,
        menuNo: mn.menuNo,
        menuNm: mn.menuNm,
        menuUrl: mn.menuUrl,
        imageFileId: mn.imageFileId,
        relateImagePath: mn.relateImagePath,
        children: mn.children,
        child: p,
      };

      let upper;
      if (mn.depth === 3) {
        menues.forEach(items => {
          if (items.depth === 1) {
            items.children.forEach(item => {
              if (item.menuNo === mn.upperMenuNo) {
                upper = item;
              }
            });
          }
        });
      } else {
        upper = { ...menues.filter(m => m.menuNo === mn.upperMenuNo)[0] };
      }

      if (upper?.depth) {
        const upperObj = handleSelectedMenu(upper, obj, loopCnt + 1);
        return upperObj;
      }

      return obj;
    },
    [menues],
  );

  /**
   * menu, menues의 값이 변경되면 선택 된 메뉴 기준 menues에서
   * 위치를 찾아 setSelMenu에 설정
   */
  useEffect(() => {
    if (menu) {
      if (menu.depth > 1) {
        setSelMenu(handleSelectedMenu(menu));
      }
    }
  }, [handleSelectedMenu, menu, menues]);

  const newProps = {
    ...props,
    menu,
    selMenu,
  };

  return <MainLayoutView {...newProps} />;
}

MainLayout.propTypes = {
  menues: PropTypes.arrayOf(
    PropTypes.objectOf(PropTypes.oneOf([PropTypes.string, PropTypes.number])),
  ),
};

MainLayout.defaultProps = {
  menues: [],
};

export default MainLayout;
