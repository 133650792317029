import React, { useState, useEffect } from 'react';

import SiteHeaderView from './SiteHeaderView';

function SiteHeader(props) {
  const [isTop, setIsTop] = useState(true);
  const [isGnbOpen, setGnb] = useState(false); // 메뉴
  const [isMenu, setIsMenu] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleListenScrollEvent);
    return () => {
      window.removeEventListener('scroll', handleListenScrollEvent);
    };
  }, []);

  const handleListenScrollEvent = () => {
    window.scrollY > 10 ? setIsTop(false) : setIsTop(true);
  };

  const handleSetGnbMenu = bool => setIsMenu(bool);

  const handleOverGnb = bool => setGnb(bool);

  const newProps = {
    ...props,
    isTop,
    isGnbOpen,
    isMenu,
    onSetGnbMenu: handleSetGnbMenu,
    onOverGnb: handleOverGnb,
  };
  return <SiteHeaderView {...newProps} />;
}

export default SiteHeader;
