// Grid Link Cell Style
export const GRID_LINK_CELL_STYLE = {
  color: '#1890FF',
  // fontWeight: 'bold',
  cursor: 'pointer',
  // whiteSpace: 'normal',
  // lineHeight: '1.5rem',
};

export const PREFIX = 'BN';

/**
 * 사이트 아이디
 * 나중에는 환경 변수에서 받을 정보
 */
export const SITE_ID = process.env.REACT_APP_SITE_ID;

/**
 * Local Storage 선언
 */
export const LOCAL_STORAGE = localStorage;

/**
 * Session Storage 선언
 */
export const SESSION_STORAGE = sessionStorage;

/**
 * 검색 정보 저장 용 변수
 */
export const SEARCH_KEY = 'SearchValue';

/**
 * 검색 정보 저장 용 변수
 */
export const PERSISTENT_INFO = `${PREFIX}.PERSISTENT`;

/**
 * Map 용 더미 API 데이터
 */
export const mapData = {
  type1: {
    location: {
      lat: 37.5654,
      lng: 127.0021,
    },
    address: {
      title: '을지로 본점',
      value: '서울시 중구 동호로 347(오장동) 제이디타워 3층~5층',
    },
    tel: {
      title: 'TEL',
      value: '02)2183-1700',
    },
    fax: {
      title: 'FAX',
      value: '02)2265-2004',
    },
  },
  type2: {
    location: {
      lat: 37.5288,
      lng: 126.9211,
    },
    address: {
      title: '여의도 지사',
      value: '서울 영등포구 국회대로70길 23 용산빌딩 9층 (여의도동)',
    },
    tel: {
      title: 'TEL',
      value: '02)320-8281',
    },
    fax: {
      title: 'FAX',
      value: '02)761-8281',
    },
  },
  type3: {
    location: {
      lat: 37.491,
      lng: 126.7225,
    },
    address: {
      title: '부평 지사',
      value: '인천 부평구 광장로 4 코아빌딩 7층, 10층 (부평동)',
    },
    tel: {
      title: 'TEL',
      value: '02)320-8321',
    },
    fax: {
      title: 'FAX',
      value: '032)505-7005',
    },
  },
  type4: {
    location: {
      lat: 37.4803,
      lng: 126.8842,
    },
    address: {
      title: '가산 지사',
      value:
        '서울 금천구 벚꽃로 286 삼성리더스타워 11층 1110호 1111호 (가산동)',
    },
    tel: {
      title: 'TEL',
      value: '02)2183-1352',
    },
    fax: {
      title: 'FAX',
      value: '02)855-7010',
    },
  },
  type5: {
    location: {
      lat: 37.4869,
      lng: 126.9922,
    },
    address: {
      title: '방배 지사',
      value: '서울 서초구 서초대로 88 유니온빌딩 6층 (방배동)',
    },
    tel: {
      title: 'TEL',
      value: '02)320-8230',
    },
    fax: {
      title: 'FAX',
      value: '02)588-5080',
    },
  },
};
