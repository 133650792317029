import { createSlice } from '@reduxjs/toolkit';

import { defaultQuery as postApi } from '../../config/utils/network';
import {
  SESSION_STORAGE,
  PERSISTENT_INFO,
  SITE_ID,
} from '../../config/constants';
import { listToTree } from '../../config/utils/util';

const initialStateValues = {
  menu: {},
  menues: [],
};

const menuSlice = createSlice({
  name: 'menu',
  initialState: () => {
    let selectedMenu = null;
    const menu = SESSION_STORAGE.getItem(PERSISTENT_INFO);
    selectedMenu = JSON.parse(menu);
    // LOCAL_STORAGE.removeItem(PERSISTENT_INFO);
    return {
      ...initialStateValues,
      menu: selectedMenu,
      type: 0,
    };
  },
  reducers: {
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    setMenues: (state, action) => {
      state.menues = action.payload;
    },
  },
});

// actions
export const { setMenu, setMenues } = menuSlice.actions;

// selector
export const menuSelector = state => state.menu;

// reducer
export default menuSlice.reducer;

export function fetchMenues() {
  const siteId = SITE_ID;

  return async dispatch => {
    try {
      const { data } = await postApi('/api/menu/findUserMenuList', {
        siteId,
      });

      const menues = data.resultList;
      if (menues && Array.isArray(menues) && menues.length > 0) {
        dispatch(setMenues(listToTree(menues)));
      }
    } catch (error) {
      console.error('🚀 ~ store/menu/reducer.js fetchMenues ~ error', error);
    }
  };
}
