import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * 페이지 이동시 스크롤을 강제로 상단으로 이동
 */
export default function ScrollToTop() {
  const { pathname, search } = useLocation();

  /**
   * 컨텐츠 탭 메뉴에 파라미터 추가 : ?type=contents
   * 현재는 파라미터가 있으면 scrollTo가 동작하지 않게 제어
   * 추후 파라미터를 받는 부분이 있다면 type=contents를 구분하여 동작 제어 해야함
   */
  useEffect(() => {
    if (search?.length < 1) {
      window.scrollTo(0, 0);
    }
  }, [pathname, search]);

  return null;
}
