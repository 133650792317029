import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

function ListView({ notiList, dataList, onMoveNotiDetail, onMoveDetail }) {
  return (
    <StyledListUl className="c-bbs-board c-bbs-board__list">
      <li className="list-head">
        <div className="num">번호</div>
        <div className="title">제목</div>
        <div className="writer">작성자</div>
        <div className="date">등록일</div>
        <div className="hit">조회</div>
      </li>
      {notiList?.map(row => {
        return (
          <li>
            <div className="num">
              <span className="notice">공지</span>
            </div>
            <div className="title">
              <span className="category">알림</span>
              <Link to={`/board/${row?.bbsId}/detail/${row?.nttId}`}>
                {row?.nttSj}
              </Link>
              <span className="ico-new">
                <span className="sr-only">새글</span>
              </span>
              <i className="ri-attachment-2">
                <span className="sr-only">첨부파일</span>
              </i>
            </div>
            <div className="writer">글쓴이</div>
            <div className="date">{row?.frstRegisterPnttm}</div>
            <div className="hit">10</div>
          </li>
        );
      })}
      {dataList && dataList.length > 0 ? (
        dataList?.map(row => {
          return (
            <li>
              <div className="num">{row?.rnum}</div>
              <div className="title">
                <span className="category">알림</span>
                <Link to={`/board/${row?.bbsId}/detail/${row?.nttId}`}>
                  {row?.nttSj}
                </Link>
                <span className="ico-new">
                  <span className="sr-only">새글</span>
                </span>
                <i className="ri-attachment-2">
                  <span className="sr-only">첨부파일</span>
                </i>
              </div>
              <div className="writer">글쓴이</div>
              <div className="date">{row?.frstRegisterPnttm}</div>
              <div className="hit">10</div>
            </li>
          );
        })
      ) : (
        <li className="empty">
          <i className="ri-edit-2-line" />
          <p>등록된 게시글이 없습니다.</p>
        </li>
      )}
    </StyledListUl>
  );
}

const StyledListUl = styled.ul`
  &.c-bbs {
    /* 탭(카테고리&말머리) */
    &__total {
      font-size: 1.7rem;
      em {
        color: var(--color-main);
        font-weight: 500;
      }
    }
    /* 기본 테이블형 게시판 */
    &-board {
      /* 기본 테이블형 게시판 : 리스트 */
      &__list {
        margin-top: 2rem;
        border-top: 2px solid var(--color-black);
        li {
          position: relative;
          border-bottom: 1px solid var(--color-gray-d9d9d9);
          > div {
            text-align: center;
          }
          &.empty {
            width: 100%;
            padding: 8rem 0;
            font-size: 1.7rem;
            i {
              color: var(--color-gray-d9d9d9);
              font-size: 4.8rem;
            }
          }
          &.list-head {
            > div {
              padding: 1.5rem 0.5rem;
              font-weight: 700;
            }
          }
          &:not(.list-head) {
            > div {
              &.title {
                text-align: left;
                a {
                  display: inline-block;
                  color: var(--color-black);
                  ${({ theme }) => theme.text.textOverflow}
                }
                .category {
                  color: var(--color-main);
                  text-align: center;
                  font-weight: 500;
                  margin-right: 1rem;
                }
                .ico-new {
                  &::before {
                    content: 'N';
                    display: inline-block;
                    width: 2rem;
                    height: 2rem;
                    margin-left: 0.8rem;
                    line-height: 2rem;
                    vertical-align: middle;
                    color: var(--color-white);
                    font-size: 1.2rem;
                    text-align: center;
                    border-radius: 50%;
                    background: var(--color-red-FF3200);
                  }
                }
                i[class*='ri-attachment'],
                i[class*='ri-file'] {
                  margin-left: 0.5rem;
                  color: var(--color-blue-0e4194);
                  font-size: 2rem;
                }
              }
            }
          }
        }
      }
    }
  }
`;

ListView.propTypes = {
  notiList: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  dataList: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
  onMoveNotiDetail: PropTypes.func,
  onMoveDetail: PropTypes.func,
};

ListView.defaultProps = {
  notiList: [],
  dataList: [],
  onMoveNotiDetail: () => {},
  onMoveDetail: () => {},
};

export default ListView;
