import { configureStore } from '@reduxjs/toolkit';
import reducer from './index';

/**
 * Store 구성
 */
const store = configureStore({
  reducer,
});

export default store;
