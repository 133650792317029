import { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { fetchMenues } from '../store/menu/reducer';

function useStoreMenu() {
  const dispatch = useDispatch();
  const menues = useSelector(state => state.menu.menues, shallowEqual);

  useEffect(() => {
    if (menues?.length < 1) {
      dispatch(fetchMenues());
    }
  }, [dispatch, menues?.length]);

  return { menues };
}

export default useStoreMenu;
