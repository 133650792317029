import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CategoryView from './CategoryView';
import { SESSION_STORAGE, SEARCH_KEY } from '../../../config/constants';

function Category({ categoryOption, onCategory }) {
  const searchValue = SESSION_STORAGE.getItem(SEARCH_KEY);

  const [ref, setRef] = useState();
  const [category, setCategory] = useState('');

  const handleCategoryTrigger = (event, key) => {
    setCategory(key);
    onCategory && onCategory(event, key);
  };

  const handleClearSearch = () => {
    setCategory('');
  };

  useEffect(() => {
    if (!searchValue) {
      handleClearSearch();
      if (ref?.current) {
        ref.current.value = '';
      }
    }
  }, [searchValue]);

  const props = {
    category,
    categoryOption,
    onCategoryTrigger: handleCategoryTrigger,
    onSetRef: setRef,
  };
  return <CategoryView {...props} />;
}

Category.propTypes = {
  category: PropTypes.string,
  categoryOption: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  ),
  onCategory: PropTypes.func,
  onCategoryTrigger: PropTypes.func,
  onChangeCategory: PropTypes.func,
};

Category.defaultProps = {
  category: '',
  categoryOption: {},
  onCategory: () => {},
  onCategoryTrigger: () => {},
  onChangeCategory: () => {},
};

export default Category;
