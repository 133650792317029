import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { mapData } from '../../../config/constants';
import { setMenu } from '../../../store/menu/reducer';
import { getTreeMenu } from '../../../config/utils/util';

import MapView from './MapView';

/**
 * 회사소개
 * @returns
 */
function Map() {
  const dispatch = useDispatch();
  const menues = useSelector(state => state.menu.menues, shallowEqual);
  const { type: typeId } = useParams();

  useEffect(() => {
    if (menues && menues.length > 0) {
      const menu = getTreeMenu(menues, typeId);
      if (menu) {
        dispatch(setMenu(menu));
      }
    }
  }, [menues, typeId, dispatch]);

  const props = {
    mapInfo: mapData[typeId],
  };

  return <MapView {...props} />;
}

export default Map;
