/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Menu from '../../molecules/Menu/Menu';
import Button from '../../atoms/Button';
import Logo from '../../atoms/Icon/Logo';

/**
 * Header
 * @returns
 */
function SiteHeaderView(props) {
  const { isTop, isGnbOpen, isMenu, onSetMenu, onSetGnbMenu, onOverGnb } =
    props;

  return (
    <StyledHeader
      className={`site-layout__header${isGnbOpen ? ' gnb-open' : ''}${
        isTop ? '' : ' is-scroll'
      }`}
      onMouseOver={() => onOverGnb(true)}
      onMouseLeave={() => {
        if (!isMenu) onOverGnb(false);
      }}
    >
      <div className="c-header">
        {/* S::logo */}
        <h1 className="c-header__logo">
          <Link
            to="/main"
            onClick={() => {
              onSetMenu && onSetMenu({});
              onOverGnb && onOverGnb(false);
              onSetGnbMenu && onSetGnbMenu(false);
            }}
          >
            <Logo />
            <span className="sr-only">Playground Demo</span>
          </Link>
        </h1>
        {/* E::logo */}
        <Button
          type="button"
          className="btn-menu--open"
          size="xs"
          variant="link"
          onClick={() => {
            if (isMenu) {
              onOverGnb && onOverGnb(false);
              onSetGnbMenu && onSetGnbMenu(false);
            } else {
              onOverGnb && onOverGnb(true);
              onSetGnbMenu && onSetGnbMenu(true);
            }
          }}
        >
          <i className="ri-menu-2-fill" />
          <span className="sr-only">주메뉴 열기</span>
        </Button>
        <Menu {...props} />
      </div>
    </StyledHeader>
  );
}

SiteHeaderView.propTypes = {
  isTop: PropTypes.bool.isRequired,
  isGnbOpen: PropTypes.bool.isRequired,
  isMenu: PropTypes.bool.isRequired,
  onSetMenu: PropTypes.func,
  onSetGnbMenu: PropTypes.func.isRequired,
  onOverGnb: PropTypes.func.isRequired,
};

SiteHeaderView.defaultProps = {
  onSetMenu: () => {},
};

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  background: transparent;
  transition: opacity 0.8s ease, all 0.3s ease;
  opacity: 1;
  z-index: 150;
  &.is-scroll,
  &.gnb-open {
    background-color: var(--color-white);
    box-shadow: 0px 2px 8px 0px var(--color-black-rgba-5);
    h1 {
      z-index: 51;
      a {
        svg {
          g {
            path {
              fill: var(--color-black);
            }
          }
        }
      }
    }
    .gnb {
      .list--depth1 {
        > li {
          > a {
            color: var(--color-black);
            :hover {
              color: var(--color-point-sub2);
            }
          }
        }
      }
    }
    .util {
      .custom {
        span:not(ico) {
          color: var(--color-black);
        }
      }
    }
  }
  .c-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 184.8rem;
    padding: 0 2.4rem;
    margin: 0 auto;
    transition: all 0.3s ease;
    z-index: 1;
  }
  h1 {
    flex: 0 0 15.5rem;
    line-height: 1;
    a {
      display: inline-block;
    }
  }
  .util {
    text-align: right;
    .custom {
      display: inline-block;
      &:hover {
        .ico {
          background-color: var(--color-main);
          svg {
            g {
              g {
                g {
                  path {
                    &:not([fill='none']) {
                      fill: var(--color-white);
                    }
                    &[fill='none'] {
                      stroke: var(--color-white);
                    }
                  }
                }
              }
              line {
                stroke: var(--color-white);
              }
            }
          }
        }
      }
      .ico {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 4.8rem;
        height: 4.8rem;
        border-radius: 50%;
        background: var(--color-gray-DCDCDC);
        transition: all 0.35s ease;
      }
      span:not(ico) {
        color: var(--color-white);
        font-family: var(--font-GmarketSans);
        font-size: 1.2rem;
      }
    }
  }

  @media ${({ theme }) => theme.media.xxlMin} {
    .util {
      flex: 0 0 15.5rem;
    }
  }
  @media ${({ theme }) => theme.media.lgMin} {
    .btn-menu--open,
    .btn-menu--close,
    .gnb-util {
      display: none;
    }
    .util {
      .custom {
        position: fixed;
        top: 27%;
        right: 2%;
      }
    }
  }
  @media ${({ theme }) => theme.media.lg} {
    .c-header {
      height: 8rem;
    }
    h1 {
      a {
        svg {
          display: inline-block;
          width: 95%;
        }
      }
    }
    .util {
      .custom {
        position: relative;
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-right: 4rem;
        z-index: 50;
      }
    }
    &.gnb-open,
    &.is-scroll {
      .btn-menu--open {
        color: var(--color-black);
      }
    }
    /* GNB btn */
    .btn-menu--open {
      position: absolute;
      top: 1rem;
      right: 0;
      font-size: 3.2rem;
      color: var(--color-white);
    }
  }
`;

export default SiteHeaderView;
