/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import SwiperCore, { Navigation, Pagination, Mousewheel } from 'swiper';
import Button from '../../../atoms/Button';
import { getFileData } from '../../../../config/utils/util';

// import Comment from '../../../organisms/Comment';

function RealEstateDetailView({ detail, fileList }) {
  const swiperRef = useRef();

  const goodsName = {
    IEM001: '사건번호',
    IEM002: '감정가',
    IEM003: '최저가',
    IEM004: '낙찰가',
    IEM005: '사업지구명',
    IEM006: '사업시행자',
    IEM007: '위 치',
    IEM008: '면 적',
    IEM009: '법 률',
  };

  const handlerRendering = tmpFiles => {
    const files = getFileData(tmpFiles);
    return (
      files &&
      files.length > 0 &&
      files?.map(slider => (
        <SwiperSlide>
          <div className="thumb">
            <div>
              <img src={slider.url} alt={slider.name} />
            </div>
          </div>
        </SwiperSlide>
      ))
    );
  };

  SwiperCore.use([Navigation, Pagination, Mousewheel]);
  return (
    <StyledSubContainer isVisible className="sub-support">
      <div className="inner-container__sub">
        {/* S::리스트형 게시판 - 상세 */}
        <article className="c-bbs">
          <div className="c-bbs-list c-bbs-list__view">
            <div className="c-bbs-list__view--header">
              <strong className="title">
                {detail?.result?.nttSj ?? '제목이 없습니다.'}
              </strong>
              <ul className="item">
                <li>
                  <i className="ri-user-3-line" />
                  <span>관리자</span>
                </li>
                <li>
                  <i className="ri-calendar-line" />
                  <span>
                    {detail?.result?.frstRegisterPnttm ?? '날짜가 없습니다.'}
                  </span>
                </li>
                <li>
                  <i className="ri-eye-line" />
                  <span>{detail?.result?.inqireCo ?? 0}</span>
                </li>
              </ul>
            </div>
            <div className="c-bbs-list__view--body">
              <div className="view-content">
                <div className="c-re">
                  <div>
                    <StyledSwiper
                      navigation
                      pagination
                      mousewheel
                      modules={[Navigation, Pagination, Mousewheel]}
                      observer
                      observeParents
                    >
                      {fileList?.fileList?.length > 0 &&
                        handlerRendering(fileList.fileList)}
                    </StyledSwiper>
                  </div>
                  <div className="c-re__box">
                    <p className="title">경매정보</p>
                    <ul className="c-re__list">
                      {detail?.result?.iemList
                        ?.filter(m => m.registSeCd === 'REG001')
                        ?.map(m => (
                          <li>
                            <strong>{goodsName[m.iemCd]}</strong>
                            <span>{m.iemDc}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="c-re__box">
                    <p className="title">개발정보</p>
                    <ul className="c-re__list">
                      {detail?.result?.iemList
                        ?.filter(m => m.registSeCd === 'REG002')
                        ?.map(m => (
                          <li>
                            <strong>{goodsName[m.iemCd]}</strong>
                            <span>{m.iemDc}</span>
                          </li>
                        ))}
                    </ul>
                  </div>
                  <div className="c-re__box">
                    <p className="title">추진내역</p>
                    <div
                      className="c-re__info"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: detail?.result?.nttCn,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="c-bbs__btn">
            <Link to={`/board/${detail?.result?.bbsId}`}>
              <Button type="button" variant="info">
                목록
              </Button>
            </Link>
          </div>
        </article>
        {/* E::리스트형 게시판 - 상세 */}
      </div>
    </StyledSubContainer>
  );
}

const StyledSubContainer = styled.div`
  h3 {
    text-align: center;
  }
  .c-bbs {
    &-list {
      /* 상세페이지 */
      &__view {
        &--header {
          padding-bottom: 2.4rem;
          border-bottom: 2px solid var(--color-black);
          .title {
            font-size: 2.6rem;
            font-weight: 500;
            & + .item {
              margin-top: 1rem;
            }
          }
          .item {
            display: flex;
            color: var(--color-gray-737373);
            li {
              display: flex;
              align-items: center;
              position: relative;
              & + li {
                margin-left: 1.6rem;
                padding-left: 1.6rem;
                &::before {
                  content: '';
                  position: absolute;
                  left: 0;
                  top: 50%;
                  transform: translateY(-50%);
                  width: 1px;
                  height: 14px;
                  border-left: 1px solid var(--color-gray-d9d9d9);
                }
              }
              i + span {
                margin-left: 0.5rem;
              }
            }
          }
        }
        &--body {
          /* padding: 4.5rem 3.2rem; */
          padding: 4.5rem 0;
          border-bottom: 1px solid var(--color-gray-d9d9d9);
          /* 컨텐츠 영역 */
          .view-content {
            font-size: 1.7rem;
          }
          /* 첨부파일 */
          .attachment {
            display: flex;
            margin-top: 4rem;
            dt {
              flex: 0 0 10rem;
              padding-top: 1rem;
            }
            dd {
              ul {
                display: flex;
                flex-wrap: wrap;
                li {
                  margin: 0.4rem;
                  a {
                    display: inline-block;
                    padding: 0.5rem 1.6rem;
                    color: var(--color-black);
                    border-radius: 4px;
                    background: var(--color-gray-eaeaea);
                    &:hover {
                      text-decoration: underline;
                      text-underline-position: under;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    /* 댓글 */
    &__comment {
      margin-top: 3.2rem;
      .comment-total {
        margin-bottom: 1rem;
        strong {
          color: var(--color-main);
        }
      }
      .comment-form {
        margin-bottom: 1.6rem;
        &__item {
          & + .comment-form__item {
            margin-top: 0.8rem;
          }
          span + span,
          span + button {
            margin-left: 0.8rem;
          }
          textarea {
            width: 100%;
          }
        }
      }
      .comment-list {
        padding: 2rem;
        background: var(--color-gray-f7f7f7);
        &__item {
          position: relative;
          &:not(.comment-list__item--noitem) {
            padding-bottom: 2rem;
            margin-bottom: 2rem;
            border-bottom: 1px dashed var(--color-gray-d9d9d9);
          }
          &:last-child {
            margin-bottom: 0;
          }
          &--noitem {
            text-align: center;
            padding: 10rem 0;
          }
          ${props => `
              &--depth${props.depthValue} {
              padding-left: 2rem;
              &::before,
              &::after {
                content: '';
                position: absolute;
                left: 6px;
                display: inline-block;
                background: var(--color-gray-969696);
              }
              &::before {
                top: 2px;
                width: 1px;
                height: 8px;
              }
              &::after {
                top: 10px;
                width: 8px;
                height: 1px;
              }
            }
          `}
          &--depth1 {
            padding-left: 2rem;
            &::before,
            &::after {
              content: '';
              position: absolute;
              left: 6px;
              display: inline-block;
              background: var(--color-gray-969696);
            }
            &::before {
              top: 2px;
              width: 1px;
              height: 8px;
            }
            &::after {
              top: 10px;
              width: 8px;
              height: 1px;
            }
          }
          &--depth2 {
            padding-left: 3rem;
            &::before,
            &::after {
              content: '';
              position: absolute;
              left: 16px;
              display: inline-block;
              background: var(--color-gray-969696);
            }
            &::before {
              top: 2px;
              width: 1px;
              height: 8px;
            }
            &::after {
              top: 10px;
              width: 8px;
              height: 1px;
            }
          }
          .item-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &__cont {
              span + time {
                margin-left: 1rem;
              }
            }
            &__btn {
              button + button {
                margin-left: 0.8rem;
              }
            }
          }
          .item-cont {
            margin-top: 0.8rem;
          }
        }
      }
    }
    /* 이전 다음 nav */
    &__nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2.4rem;
      li {
        width: calc(50% - 2rem);
        &.nav {
          &--prev {
          }
          &--next {
            a {
              justify-content: flex-end;
              span {
                text-align: right;
                order: 2;
                i {
                  float: right;
                }
              }
            }
          }
        }
        a {
          display: flex;
          align-items: center;
          padding: 1.6rem 0;
          color: var(--color-gray-737373);
          font-size: 1.6rem;
          span {
            flex: 0 0 9rem;
          }
          strong {
            color: var(--color-black);
            font-weight: 500;
            ${({ theme }) => theme.text.textOverflow}
          }
        }
      }
    }
    /* 버튼영역 */
    &__btn {
      margin-top: 4.5rem;
      text-align: center;
    }
    /* 물건공고 */
    .c-re {
      .title {
        font-weight: 700;
      }
      &__list {
        border-top: 1px solid var(--color-black);
        li {
          border-bottom: 1px solid #666;
          strong {
            font-weight: 400;
            text-align: center;
            vertical-align: middle;
            background: #f0f2f4;
          }
          span {
            font-weight: 600;
          }
        }
      }
      &__info {
        padding: 2.4rem;
        font-size: 1.7rem;
        border: 1px solid #666;
      }
    }
  }
  @media ${({ theme }) => theme.media.lgMin} {
    /* 물건공고 */
    .c-re {
      &__list {
        display: table;
        width: 100%;
        li {
          strong {
            display: table-cell;
            height: 100%;
            width: 30rem;
            padding: 2rem;
          }
          span {
            display: table-cell;
            vertical-align: middle;
            padding: 2rem 4rem;
          }
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.mdMinlg} {
    /* 물건공고 */
    .c-re {
      &__list {
        li {
          strong {
            display: table-cell;
            height: 100%;
            width: 20rem;
            padding: 1.6rem;
          }
          span {
            display: table-cell;
            vertical-align: middle;
            padding: 1.6rem 2rem;
          }
        }
      }
    }
  }

  @media ${({ theme }) => theme.media.mdMin} {
    /* 물건공고 */
    .c-re {
      .title {
        margin-bottom: 1.6rem;
        font-size: 2.6rem;
      }
      &__box {
        margin-top: 8rem;
      }
      &__list {
        li {
          font-size: 1.7rem;
        }
      }
    }
  }
  @media ${({ theme }) => theme.media.md} {
    .c-bbs {
      &-list {
        /* 상세페이지 */
        &__view {
          &--body {
            padding-right: 0;
            padding-left: 0;
            /* 첨부파일 */
            .attachment {
              flex-direction: column;
              dt {
                flex: 1;
              }
            }
          }
        }
      }
    }
    .c-bbs {
      /* 이전 다음 nav */
      &__nav {
        li {
          a {
            strong {
              display: none;
            }
          }
        }
      }
    }
    .c-re {
      .title {
        margin-bottom: 1rem;
        font-size: 2.3rem;
      }
      &__box {
        margin-top: 5rem;
      }
      &__list {
        li {
          flex-direction: column;
          font-size: 1.7rem;
          strong {
            display: block;
            padding: 1rem;
          }
          span {
            display: block;
            padding: 1rem 0;
            text-align: center;
          }
        }
      }
    }
  }
`;

const StyledSwiper = styled(Swiper)`
  max-width: 80rem;
  .swiper-slide {
    padding: 0 0 6rem;
  }
  .thumb {
    position: relative;
    height: 0;
    padding-bottom: 75%;
    overflow: hidden;
    > div {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: hidden;
      img {
        transform: scale(1);
        transition: all 0.35s ease;
        height: 100%;
        width: 100%;
        margin: auto;
        display: block;
        object-fit: cover;
      }
    }
  }

  .swiper-pagination-bullets {
    width: auto;
    bottom: 2px;
    left: 50%;
    transform: translateX(-50%);
  }
  .swiper-button-next,
  .swiper-button-prev {
    top: auto;
    right: 0;
    bottom: 0;
    width: 3.2rem;
    height: 3.2rem;
    border: 1px solid #b4b4b4;
    left: auto;
    color: var(--color-black);
    &::after {
      font-size: 1.4rem !important;
    }
  }
  .swiper-button-next {
    right: 0;
  }
  .swiper-button-prev {
    left: 0;
  }
`;

const CommentWrap = styled.div`
  ${props => `
      padding-left: ${(parseInt(props.depthValue, 10) + 1).toString()}rem;
      &::before,
      &::after {
        content: '';
        position: absolute;
        left: ${
          parseInt(props.depthValue, 10) - 1 < 1
            ? ''
            : (parseInt(props.depthValue, 10) - 1).toString()
        }6px;
        display: inline-block;
        background: var(--color-gray-969696);
      }
      &::before {
        top: 2px;
        width: 1px;
        height: 8px;
      }
      &::after {
        top: 10px;
        width: 8px;
        height: 1px;
      }
    }
  `}
`;

RealEstateDetailView.propTypes = {
  detail: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ),
  fileList: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    ),
  ),
};

RealEstateDetailView.defaultProps = {
  detail: {},
  fileList: [],
};

export default RealEstateDetailView;
