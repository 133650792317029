import React from 'react';

const CustomerIcon = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      viewBox="0 0 34 34"
      {...props}
    >
      <g transform="translate(0 0)">
        <g transform="translate(0 0)">
          <g>
            <path
              d="M27.5,23.449V13.482c0-5.219-4.875-9.315-10.625-9.315S6.253,8.262,6.253,14.012v9.043"
              transform="translate(0.125 0.083)"
              fill="none"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <path
              d="M27.763,22.873l2.3-.768c.276,0,.812-.39.768-.768l-.005-5.377c.043-.248-.474-.768-.763-.768l-2.3-.768"
              transform="translate(0.556 0.288)"
              fill="#000"
            />
            <path
              d="M5.693,22.873l-2.3-.768c-.276,0-.812-.39-.768-.768l.005-5.377c-.044-.248.474-.768.763-.768l2.3-.768"
              transform="translate(0.052 0.288)"
              fill="#000"
            />
            <path
              d="M27.281,22.847s-1.891,6.375-9.917,6.375"
              transform="translate(0.347 0.457)"
              fill="none"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <path
              d="M19.859,29.145A2.448,2.448,0,1,1,17.411,26.7a2.448,2.448,0,0,1,2.448,2.448"
              transform="translate(0.299 0.534)"
              fill="#000"
            />
          </g>
        </g>
        <line
          y2="2.772"
          transform="translate(12.054 14.203)"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <line
          y2="2.772"
          transform="translate(21.961 14.203)"
          fill="none"
          stroke="#000"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default CustomerIcon;
