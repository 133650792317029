// 라이브러리
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

// 공통 컴포넌트
import { useQuery } from '../../../../hooks/useQuery';
import { SITE_ID } from '../../../../config/constants';

// 페이지
import RealEstateDetailView from './RealEstateDetailView';

/**
 * 회사소개
 * @returns
 */
function RealEstateDetail() {
  const { id: bbsId, nttrId: nttId } = useParams();
  const [paging, setPaging] = useState();

  /**
   * API - 상세정보 조회
   */
  const {
    data: detail,
    refetch: refetchDetail,
    dataUpdatedAt,
    // ...restDetailQuery
  } = useQuery(
    '/api/bbsAnno/selectBbsAnnoDetail',
    { siteId: SITE_ID, bbsId, nttId },
    {
      cacheTime: 0,
      enabled: !!bbsId && !!nttId,
    },
  );

  /**
   * API - 파일 목록 조회
   */
  const { data: fileList } = useQuery('/api/file/selectFileList', {
    groupId: detail?.result?.atchGroupId,
    dataUpdatedAt,
  });

  /**
   * props 세팅
   */
  const props = {
    detail,
    fileList,
    nttId,
  };

  return <RealEstateDetailView {...props} />;
}

export default RealEstateDetail;
